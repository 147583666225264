import React, { Component } from 'react';

import AuthForm from './AuthForm';

import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import isoLanguages from '../iso-languages';

import { getCodes, getName } from 'country-list';

import Toggle from './Toggle';
import Tooltip from './Tooltip';
import Switch from './Switch';
import Option from './SwitchOption';
import SectionHeader from './SectionHeader';

import HeaderButtons from './HeaderButtons';
import { AuthSubmitButton } from './Buttons';
import { TargetingSlider } from './Sliders';
import Confirm from './Confirm';

import AccountSettingsVisualDisplay from './AccountSettingsVisualDisplay';
import SurveyPreviewHeader from '../components/SurveyPreviewHeader';

import { getPollDisplayType, generatePageDisplaySettings, getDisplaySettings  } from '../utils';

import _ from 'lodash';

const isoLanguageCodes = isoLanguages.map(({ code, name }) => ({ value: code, label: `${name} (${code})` }));

const getLanguageName = (code) => {
  return `${_.find(isoLanguages, { code }).name} (${code})`;
}
const countryCodes = getCodes();
const countries = countryCodes.map((code) => {
  return { value: code, label: getName(code) }
})

const applyTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: "#2167f5",
    danger: "#F26c57",
  }
});

class InlineInput extends Component {
  onChange(e) {
    const value = e.target.value.replace(/[^\d.-]/g,'');
    const name = e.target.name;

    this.props.onChange(name, value);
  }

  render() {
    return (
      <div className="inline-input" style={this.props.style || { marginTop: 15, marginBottom: 10 }}>
        <label style={{ marginBottom: 5 }}>{ this.props.label }{ this.props.tooltip }</label>
        <span className="prefix">{ this.props.prefix }</span>
        <input
          type="text"
          name={ this.props.name }
          value={ this.props.value }
          onChange={ this.onChange.bind(this) }
        />
        <span className="suffix">{ this.props.suffix }</span>
      </div> 
    );
  }
}

class TargetingInputSelect extends Component {
  constructor(props) {
    super(props);
    this.state = { isMenuOpen: false };
  }

  onChange(selectedOptions) {
    let list;
    if (selectedOptions) {
      list = selectedOptions.map(({ value }) => value);
    }

    const value = { ...this.props.value };
    value[this.props.listName] = list;

    this.props.onChange(this.props.name, value);
  };

  renderSelect() {
    if (!this.props.value) {
      return;
    }

    let list = this.props.value[this.props.listName];

    if (list) {
      list = list.map(this.props.generateSelectOption)
    }

    return (<span style={{ marginTop: 15, marginBottom: 25, display: 'block' }}>
      <Select 
        options={this.props.selectOptions}
        value={list}
        isMulti
        name="react-select"
        className={this.state.isMenuOpen ? 'react-select active' : 'react-select'}
        onMenuOpen={() => this.setState({ isMenuOpen: true })}
        onMenuClose={() => this.setState({ isMenuOpen: false })}
        theme={applyTheme}
        onChange={this.onChange.bind(this)}
      />
    </span>)
  }

  render() {
    return (<div style={{ marginBottom: 25 }} >
      <div className="inline-toggle">
        <label>{ this.props.label }<Tooltip>{ this.props.tooltip }</Tooltip></label>

        <InputSwitch
          vertical={true}
          value={this.props.value}
          options={this.props.options}
          input={this.renderSelect()}
          compare={(a = {}, b = {}) => _.isEqual(a.type, b.type)}
          onChange={(value) => {
            this.props.onChange(`${this.props.name}`, value);
          }}
        />
      </div>
    </div>)
  }
}

class TargetingInputCreateSelect extends TargetingInputSelect {
  onChange(selectedOptions) {
    let list;
    if (selectedOptions) {
      list = selectedOptions.map(({ value }) => value);
    }

    const value = { ...this.props.value };
    value[this.props.listName] = list;

    this.props.onChange(this.props.name, value);
  };

  renderSelect() {
    if (!this.props.value) {
      return;
    }

    let list = this.props.value[this.props.listName];

    if (list) {
      list = list.map((val) => ({ value: val, label: val }))
    }

    return (<span style={{ marginTop: 15, marginBottom: 25, display: 'block' }}>
      <CreatableSelect
        value={list}
        isMulti
        name="react-select"
        className={this.state.isMenuOpen ? 'react-select active' : 'react-select'}
        onMenuOpen={() => this.setState({ isMenuOpen: true })}
        onMenuClose={() => this.setState({ isMenuOpen: false })}
        theme={applyTheme}
        onChange={this.onChange.bind(this)}
      />
    </span>);
  }
}

class KeyValInput extends TargetingInputSelect {
  onChange(e, idx) {
    e.preventDefault();
    e.stopPropagation();

    const name = e.target.name;
    const value = e.target.value;

    let metadata = this.props.value.entries || [{ key: '', value: '' }];
    if (metadata.length === 0) {
      metadata = [{ key: '', value: ''}];
    }

    metadata[idx][name] = value;
    const update = { ...this.props.value };
    update.entries = metadata;
    this.props.onChange(this.props.name, update);
  }

  renderSelect() {
    if (!this.props.value) {
      return;
    }

    let metadata = this.props.value.entries || [{ key: '', value: '' }];
    if (metadata.length === 0) {
      metadata = [{ key: '', value: ''}];
    }

    return (<span className="metadata-row-input" style={{ marginTop: 15, marginBottom: 25, display: 'block' }}>
      <div className="labels">
        <label>Key</label>
        <label>Value</label>
      </div>
      { metadata.map(({ key, value }, idx) => {
        return (<div className="row">
          <input name="key" placeholder="key" value={key}  onChange={(e) => this.onChange(e, idx)}/>
          <input name="value" placeholder="value" value={value} onChange={(e) => this.onChange(e, idx)}/>
        </div>)
      })}
      <div className="add-metadata" onClick={() => {
        let metadata = this.props.value.entries || [{ key: '', value: '' }];
        if (metadata.length === 0) {
          metadata = [{ key: '', value: ''}];
        }
        metadata.push({ key: '', value: ''});

        const update = { ...this.props.value };
        update.entries = metadata;
        this.props.onChange(this.props.name, update);
      }}>Add Metadata</div>
    </span>);
  }
}

class InputSwitch extends Switch {
  render() {
    return (<div
      className={`switch ${this.props.disabled ? 'disabled' : ''} ${this.props.vertical ? 'vertical' : ''}`}
      active={this.props.active}
    >
      { this.props.options.map(option => {
        let selected;
        const compare = this.props.compare || _.isEqual;

        if (compare(option.value, this.props.value)) {
          selected = true;
        }

        return <span><Option
          {...option}
          key={option.value}
          selected={selected}
          onClick={this.handleClick.bind(this)}
        />
        { selected && option.showInput ? this.props.input : null }
        </span>
      }) }
    </div>)
  }
}

const technologies = [
  { value: 'chrome', label: 'Chrome' },
  { value: 'safari', label: 'Safari' },
  { value: 'firefox', label: 'Firefox' },
  { value: 'internet-explorer', label: 'Internet Explorer' },
  { value: 'edge', label: 'Microsoft Edge' }
];

const getTechnology = (v) => {
  return _.find(technologies, ({ value }) => v === value);
}

export default class TargetingSettingsForm extends AuthForm {
  constructor(props) {
    super(props);

    const settings = { ...this.props.settings };
    this.state = {
      valid: undefined,
      inputs: {
        targetingSettings: settings
      },
      launcherActive: true
    };

    this.inputStates = {};
    this.setInitialState();
  }

  updateSetting(key, value) {
    const inputs = this.state.inputs;
    const targetingSettings = { ...inputs.targetingSettings };
    targetingSettings[key] = value;
    inputs.targetingSettings = targetingSettings;
    this.setState({ inputs });
  }

  renderInputs() {
    let pageDisplaySettings = this.props.account.pageDisplaySettings || generatePageDisplaySettings(this.props);
    if (this.props.poll && this.props.poll.pageDisplaySettings) {
      pageDisplaySettings = this.props.poll.pageDisplaySettings;
    }

    return (<div className="account-display">
      <div className="split big">
        <div className="account-settings has-dynamic-input" style={{ marginBottom: 0, paddingRight: 0, marginTop: 0 }}>

          { this.props.title }

          <SectionHeader
            title=<span><i class="fas fa-percent"></i>Percentage</span>
            subtitle="Adjust the percent of qualified participants that will see this survey."
            bottom={true}
            className="small no-margin"
          />

          <div className="card">
            <InlineInput
              label="Percentage"
              tooltip={<Tooltip>Example: Enter 50 if you want to show your survey to 50% of your visitors.</Tooltip>}
              name="percentage"
              value={ this.state.inputs.targetingSettings.percentage === undefined ? 100 : this.state.inputs.targetingSettings.percentage }
              prefix="Show to"
              suffix="% of the traffic matching the rules below."
              idx={1}
              style={{ marginTop: 0 }}
              onChange={this.updateSetting.bind(this)}
            />
          </div>

          <SectionHeader
            title=<span><i class="fas fa-bullseye"></i>Targeting Rules</span>
            subtitle="Adjust the rules that must be met in order to qualify for this survey."
            bottom={true}
            className="small no-margin"
          />

          <div className="card">
            {/*
            <TargetingSlider
              value={this.state.inputs.targetingSettings.percentage === undefined ? 100 : this.state.inputs.targetingSettings.percentage}
              onChange={(value) => {
                this.updateSetting('percentage', value);
              }}
            />
            */}

            <TargetingInputCreateSelect 
              value={this.state.inputs.targetingSettings.referral}
              listName="domains"
              label="Referral"
              tooltip="Dynamically show the survey based on where the user came from before they landed on your website."
              name={'referral'}
              onChange={this.updateSetting.bind(this)}
              options={[
                { label: 'Traffic from anywhere on the web.', value: undefined },
                { label: 'Traffic that went directly to your site.', value: { type: 'direct' } },
                { label: 'Traffic to your site from a search engine.', value: { type: 'search-engine' } },
                { label: 'Traffic from specific domains.', value: { type: 'only', domains: [] }, showInput: true },
                { label: 'Organic traffic to your site.', value: { type: 'organic' } },
                { label: 'Paid traffic to your site.', value: { type: 'paid' } },
                { label: 'All Traffic except specific domains.', value: { type: 'except', domains: [] }, showInput: true },
              ]}
            />

            <TargetingInputSelect
              value={this.state.inputs.targetingSettings.geography}
              listName="countries"
              selectOptions={countries}
              generateSelectOption={(code) => {
                return { value: code, label: getName(code) }
              }}
              label="Geography"
              tooltip="Dynamically show the survey based on the country of your users."
              name={'geography'}
              onChange={this.updateSetting.bind(this)}
              options={[
                { label: 'Show everywhere.', value: undefined },
                { label: 'Show only in certain countries.', value: { type: 'only', countries: [] }, showInput: true },
                { label: 'Show in all countries except a few.', value: { type: 'except', countries: [] }, showInput: true }
              ]}
            />

            <TargetingInputSelect
              value={this.state.inputs.targetingSettings.language}
              listName="languages"
              selectOptions={isoLanguageCodes}
              generateSelectOption={(code) => {
                return { value: code, label: getLanguageName(code) }
              }}
              label="Languages"
              tooltip="Dynamically show the survey based on your user's language preferences."
              name={'language'}
              onChange={this.updateSetting.bind(this)}
              options={[
                { label: 'Show for all languages.', value: undefined },
                { label: 'Show only for certain languages.', value: { type: 'only', languages: [] }, showInput: true },
                { label: 'Show for all languages except a few.', value: { type: 'except', languages: [] }, showInput: true }
              ]}
            />

            <TargetingInputSelect
              value={this.state.inputs.targetingSettings.technology}
              listName="technologies"
              selectOptions={technologies}
              generateSelectOption={(code) => getTechnology(code)}
              label="Browser & Device"
              tooltip="Dynamically show the survey based on the your user's technology."
              name={'technology'}
              onChange={this.updateSetting.bind(this)}
              options={[
                { label: 'Show on all browsers & devices.', value: undefined },
                { label: 'Show only for certain browsers or devices.', value: { type: 'only', technologies: [] }, showInput: true },
                { label: 'Show in all browser & device except a few.', value: { type: 'except', technologies: [] }, showInput: true }
              ]}
            />

            <TargetingInputCreateSelect
              value={this.state.inputs.targetingSettings.ip}
              listName="ipAddresses"
              label="Ip Address"
              tooltip="Dynamically show the survey based on the ip address of your visitors. You can use CIDR notation if you want to map over a wide number of ip addresses."
              name={'ip'}
              onChange={this.updateSetting.bind(this)}
              options={[
                { label: 'Show to all IP Addresses.', value: undefined },
                { label: 'Show only to certain IP addresses.', value: { type: 'only', ipAddresses: [] }, showInput: true },
                { label: 'Show to all IP addresses except a few.', value: { type: 'except', ipAddresses: [] }, showInput: true }
              ]}
            />

            <TargetingInputSelect
              value={this.state.inputs.targetingSettings.visitors}
              listName="visitors"
              label="Visitors"
              tooltip="Dynamically show the survey based on the type of visitor."
              name={'visitors'}
              onChange={this.updateSetting.bind(this)}
              options={[
                { label: 'Show to all visitors.', value: undefined },
                { label: 'Show to returning visitors only.', value: { type: 'returning' } },
                { label: 'Show to first time visitors only.', value: { type: 'first-time' } }
              ]}
            />

            <KeyValInput
              value={this.state.inputs.targetingSettings.metadata}
              listName="entries"
              label="Metadata"
              tooltip="Dynamically show the survey based on metadata."
              name={'metadata'}
              onChange={this.updateSetting.bind(this)}
              options={[
                { label: 'Show regardless of metadata.', value: undefined },
                { label: 'Show only if certain metadata exists.', value: { type: 'only', entries: [] }, showInput: true },
                { label: 'Show only if certain metadata does not exist.', value: { type: 'except', entries: [] }, showInput: true }
              ]}
            />
          </div>

          <SectionHeader
            title=<span><i class="fas fa-street-view"></i>Page Views</span>
            subtitle="How many pages a participant must see before qualifying for this survey."
            bottom={true}
            className="small no-margin"
          />

          <div className="card">
            <div>
              <InlineInput
                label="Page Views"
                tooltip={<Tooltip>Example: Enter 5 if you want to show your survey to appear after a user has visited your site 5 times.</Tooltip>}
                name="pageViews"
                value={ this.state.inputs.targetingSettings.pageViews === undefined ? 1 : this.state.inputs.targetingSettings.pageViews }
                prefix="Show when"
                suffix="page(s) are viewed on your website."
                idx={1}
                style={{ marginTop: 0 }}
                onChange={this.updateSetting.bind(this)}
              />
            </div>
          </div>

          { this.renderActions() }
        </div>
        <div className="preview-container-wrapper">
          <SurveyPreviewHeader
            account={this.props.account}
            poll={this.props.poll}
          />
          <div className="preview-container">
            <AccountSettingsVisualDisplay
              pageDisplaySettings={pageDisplaySettings}
              slides={this.props.poll ? this.props.poll.slides : []}
              active={this.state.launcherActive}
              pollType={getPollDisplayType(this.props.poll)}
              toggleActive={() => this.setState({ launcherActive: !this.state.launcherActive })}
              belowVisualDisplay={this.props.belowVisualDisplay}
              scalePoll={false}
              {...getDisplaySettings(this.props.account, this.props.poll)}
            />
          </div>
        </div>
      </div>
    </div>);
  }

  validateSettings(e) {
    e.stopPropagation();
    e.preventDefault();

    const targetingSettings =  this.state.inputs.targetingSettings;
    const { percentage, pageViews, geography, technology, ip, referral } = targetingSettings;

    if (percentage === 0 || percentage === '') {
      this.props.flash('Percentage cannot be zero.');
      return;
    }

    if (percentage > 100) {
      this.props.flash('Percentage cannot be greater than 100.');
      return;
    }

    if (pageViews <= 0 || pageViews === '') {
      this.props.flash('Page views must be greater than zero.');
      return;
    }

    if (referral && referral.domains && referral.domains.length === 0) {
      this.props.flash('Domains cannot be blank.');
      return;      
    }

    if (geography && geography.countries && geography.countries.length === 0) {
      this.props.flash('Countries cannot be blank.');
      return;      
    }

    if (technology && technology.technologies && technology.technologies.length === 0) {
      this.props.flash('Browser & Devices cannot be blank.');
      return;      
    }

    if (ip && ip.ipAddresses && ip.ipAddresses.length === 0) {
      this.props.flash('Ip addresses cannot be blank.');
      return;      
    }

    this.onSubmit();
  }

  reset() {
    this.props.removeDisplaySettings('targetingSettings').then(() => {
      window.location.reload();
    });
  }

  renderActions() {
    return (
      <div className="actions card" style={{ marginTop: 20 }}>
        <AuthSubmitButton
          title="Save Changes" 
          disabled={!this.hasChanged()}
        />

        { this.props.poll ? 
          <button 
            className="reset"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.setState({ showResetModal: true });
            }}
          >Reset</button> : <div className="login">
            <div>Need help with targeting settings?</div>
            <a href={`https://docs.zigpoll.com/${this.props.isAccountTargetingSettingsForm ? 'accounts' : 'polls'}/targeting-settings`} target="_blank" rel="noopener noreferrer">Learn more</a>
          </div> }

        <Confirm 
          title="Are you sure?"
          subtitle={<span>This will reset your survey's targeting settings back to your defaults.</span>}
          show={this.state.showResetModal}
          onConfirm={() => {
            this.reset();
          }}
          onCancel={() => {
            this.setState({ showResetModal: false });
          }}
        />

        <HeaderButtons>
          <button 
            disabled={!this.hasChanged()}
            onClick={this.onSubmit.bind(this)}>Save Changes</button>
        </HeaderButtons>
      </div>
    )
  }

  render() {
    return (
      <form onSubmit={this.validateSettings.bind(this)} className={`${this.state.valid === false ? 'invalid' : ''} ${this.state.loading || this.props.loading ? 'loading' : ''} ${this.props.className}`}>
          { this.renderInputs() }
      </form>
    );
  }
}
