/* User Reducer */
import _ from 'lodash';

import { 
  CREATED_ACCOUNT,
  CREATING_ACCOUNT,
  UPDATED_ACCOUNT,
  UPDATING_ACCOUNT,
  DELETING_ACCOUNT,
  DELETED_ACCOUNT,
  FETCHING_ACCOUNT,
  FETCHED_ACCOUNT,
  FETCHING_ADMIN,
  FETCHED_ADMIN,
  START_KEY_LOADING,
  STOP_KEY_LOADING,
  NO_ACCOUNTS
} from '../actions/Accounts';

import { FETCHED_POLL } from '../actions/Polls';

import { CREATED_POLL, UPDATED_POLL, DELETED_POLL, ARCHIVED_POLL, UNARCHIVED_POLL } from '../actions/Polls';

import { REMOVE_NOTIFICATION } from '../actions/Dashboard';

export default function accounts(state = {}, action) {
  let account;
  let polls;
  let idx;
  let prevAccount;

  switch (action.type) {
    case START_KEY_LOADING: {
      const loadingKeys = state.loadingKeys ? [...state.loadingKeys] : [];
      loadingKeys.push(action.key);

      return { ...state, loadingKeys, loading: true }
    }
    case STOP_KEY_LOADING: {
      const loadingKeys = state.loadingKeys ? [ ...state.loadingKeys ] : [];
      const idx = state.loadingKeys[action.key];

      if (idx !== -1){
        loadingKeys.splice(idx, 1);
      }

      let loading = true;
      if (loadingKeys.length === 0) {
        loading = false;
      }

      return { ...state, loadingKeys, loading };
    }
    case CREATING_ACCOUNT:
    case UPDATING_ACCOUNT:
    case DELETING_ACCOUNT:
    case FETCHING_ACCOUNT:
    case FETCHING_ADMIN:
      return { ...state, loading: true }
    case NO_ACCOUNTS:
      return { ...state, loading: false }
    case CREATED_ACCOUNT:
    case UPDATED_ACCOUNT:
    case FETCHED_ACCOUNT:
    case ARCHIVED_POLL:
    case UNARCHIVED_POLL:
      account = {};
      prevAccount = state[action.account._id] || {};
      if (action.reset) {
        account[action.account._id] = { ...action.account };
      } else {
        account[action.account._id] = { ...prevAccount, ...action.account };
      }
      return { ...state, ...account, loading: false }
    case CREATED_POLL:
    case UPDATED_POLL:
      account = state[action.accountId];
      if (!account) { return { ...state } }

      polls = account.polls || [];
      // const poll = { _id: action.poll._id, title: action.poll.title, settings: action.poll.settings, hasNotifications: action.poll.hasNotifications, isArchived: action.poll.isArchived, isVisible: action.poll.isVisible };
      const poll = { ...action.poll };
      idx = _.findIndex(polls, o => o._id === action.poll._id);

      if (idx === -1) {
        polls.unshift(poll);
      } else {
        polls[idx] = poll;
      }

      return { ...state };
    case DELETED_POLL:
      account = state[action.accountId];
      if (!account) { return { ...state } }

      _.remove(account.polls, (p) => p._id === action.pollId);

      return { ...state }
    case DELETED_ACCOUNT:
      const accounts = { ...state };
      delete accounts[action.accountId];

      return { ...accounts, loading: false }
    case FETCHED_ADMIN:
      account = state[action.accountId];
      if (!account) { return { ...state, loading: false } }

      account.admin = action.admin;

      return { ...state, loading: false };
    case REMOVE_NOTIFICATION:
      account = state[action.accountId];
      if (!account) { return { ...state } }

      polls = account.polls;
      idx = _.findIndex(polls, o => o._id === action.pollId);

      if (idx === -1) { return { ...state } }

      polls[idx].hasNotifications = false;

      return { ...state }
    case FETCHED_POLL: {
      let account = state[action.accountId];
      const poll = action.poll;

      if (!poll || !account) { return { ...state } }

      account = { ...account }

      const polls = [ ...account.polls ];
      const idx = _.findIndex(polls, o => o._id === action.poll._id);
      polls[idx] = poll;
      account.polls = polls;

      return { ...state, [action.accountId]: account };
    }
    case 'UPDATING_SENDER_DOMAIN':
      return { ...state, loadingDomain: true }
    case 'UPDATED_SENDER_DOMAIN':
      return { ...state, loadingDomain: false }
    case 'UPDATING_PAYMENT_INFO':
      return { ...state, loadingPayment: true }
    case 'UPDATED_PAYMENT_INFO':
      return { ...state, loadingPayment: false }
    default:
      return state;
  }
}
