import _ from 'lodash';
import $ from 'jquery';

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

import * as AccountActions from '../actions/Accounts';
import * as PollActions from '../actions/Polls';
import * as RouterActions from '../actions/Router';

import ColumnLoading from './ColumnLoading';

import ColumnLayout from '../components/ColumnLayout';
import Breadcrumbs from '../components/Breadcrumbs';
import SingleFormPageHeader from '../components/SingleFormPageHeader';

import AccountEditForm from '../components/AccountEditForm';
import AccountDisplaySettingsForm from '../components/AccountDisplaySettingsForm';
import AccountEmailDisplaySettingsForm from '../components/AccountEmailDisplaySettingsForm';
import AccountSmsDisplaySettingsForm from '../components/AccountSmsDisplaySettingsForm';
import AccountPageDisplaySettingsForm from '../components/AccountPageDisplaySettingsForm';
import AccountVisibilitySettingsForm from '../components/AccountVisibilitySettingsForm';
import AccountLanguageSettingsForm from '../components/AccountLanguageSettingsForm';
import AccountTargetingSettingsForm from '../components/AccountTargetingSettingsForm';
import SurveyPreviewHeader from '../components/SurveyPreviewHeader';

import ActivePolls from '../components/ActivePolls';
import EmbedCode from '../components/EmbedCode';
import SectionHeader from '../components/SectionHeader';
import MonthlyCounter from '../components/MonthlyCounter';
import AccountIdCard from '../components/AccountIdCard';

import HeaderButtons from '../components/HeaderButtons';
import LoadingIndicator from '../components/LoadingIndicator';
import Modal from '../components/Modal';
import JSONUploader from '../components/JSONUploader';
import Tooltip from '../components/Tooltip';
import DeleteConfirm from '../components/DeleteConfirm';

import Papa from 'papaparse'


import BrowserFrame from '../components/BrowserFrame';
import Embed from '../components/Embed';
import PagePreview from '../components/PagePreview';

import { decode, encode, getTab, capitalize, getParams, generatePageDisplaySettings } from '../utils';

class AccountRow extends Component {
  render() {
    const { account, user } = this.props;

    if (!account) { return null; }

    let agencyLink = null;
    let updatePlanLink = null;
    let link = null;

    if (account.owner === user._id) {
      updatePlanLink = <div className="link update-plan"><Link className="" to={`/a/${encode(account._id)}/manage`}>Update Plan</Link></div>
      link = (<div className="link"><Link to={`/a/${encode(account._id)}/manage`}>Manage Users</Link></div>)
    }

    return <div className="account-row">
      <div className="top">
        <p className="title">{ account.title }</p>
      </div>
      <p className="plan"><span className="inline-label">Plan:</span><strong>{ account.plan.label }</strong></p>
      {/*<p className="plan"><span className="inline-label">Price:</span><strong>${ account.plan.price } <em>/mo</em></strong></p>*/}

      <div className="links">
        <div className="link dashboard"><Link to={`/dashboard/a/${encode(account._id)}`}>View Dashboard</Link></div>
        { link }
        { updatePlanLink }
        <br/>
        <div className="link learn"><a target="_blank" href="https://www.zigpoll.com/pricing" className="link manage">Learn more about our pricing plans.</a></div>
      </div>
    </div>
  }
}

class GeneralTab extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let disabled = this.state.json ? false : true;
    if (this.state.importing) {
      disabled = true;
    }
    if (this.props.polls.loading) {
      disabled = true;
    }

    return (
      <div>
        <SectionHeader
          title=<span><i className="fas fa-cog" />General</span>
          subtitle="Control your account title and domain settings."
          bottom={true}
          className="no-margin"
        />

        <AccountEditForm 
          id={this.props.accountId}
          account={this.props.account}
        />

        <MonthlyCounter account={this.props.account} user={this.props.user} />

        <div>
          <SectionHeader
            title=<span><i class="fas fa-file-invoice-dollar"></i>Subscription Plan</span>
            subtitle="Find the plan that's right for you."
          />
          <div className="card account-plan">
            <AccountRow account={this.props.account} user={this.props.user} />
          </div>
        </div>

        <AccountIdCard _id={this.props.account._id} />

        <SectionHeader
          title=<span><i class="fas fa-file-import" />Import Survey</span>
          subtitle="Migrate a survey into this acccount using an import file."
        />
        <div className="poll-management card inputs slide-form">
          <div class="input">
            <label>Upload survey json file<Tooltip><a href="https://docs.zigpoll.com/polls/import" target="_blank">Click here</a> to learn more about how to export a survey.</Tooltip></label>
          </div>
          <JSONUploader
            file={this.state.file}
            onChange={(file) => {
              if (!file) {
                const state = { ...this.state };
                state.file = undefined;
                state.json = undefined;

                return;
              }

              var reader = new FileReader();
              reader.addEventListener("loadend", (event) => { 
                const content = event.target.result;
                const poll = JSON.parse(content);
                this.setState({ json: poll, file, importing: false });
              });
              reader.readAsText(file);
            }}
          />

          <button
            className={`duplicate-poll ${this.state.json ? 'active' : 'disabled'}`}
            disabled={disabled}
            onClick={(value) => {
              this.setState({ importing: true });

              this.props.importPoll(this.state.json).then(() => {
                this.setState({ file: undefined, json: undefined });
              });
            }}
          >{ this.state.importing ? 'Uploading...' : 'Upload' }</button>
        </div>

      </div>
    );
  }
}

class VisibilityTab extends Component {
  render() {
    return (
      <div>
        {/*
        <SectionHeader
          title=<span><i class="fas fa-mouse-pointer"/>Default Visibility Settings</span>
          subtitle="Control how all surveys pop-up and behave once completed."
          tooltip="This will be the default settings for all new surveys. If you want to have a particular survey behave differently you can override these settings by navigating to that survey and clicking the visibility tab."
          bottom={true}
          className="no-margin"
        />
        */}

        <AccountVisibilitySettingsForm 
          id={this.props.accountId}
          account={this.props.account}
          belowVisualDisplay={<div className="below-visual-display">
            <i className="fas fa-arrow-turn-up" />This is a preview of your <strong>on-site</strong> behavior defaults. To edit the style for a specific survey only select the <Link to={`/a/${encode(this.props.accountId)}?tab=surveys`}>survey</Link> then click <strong>Settings → Behavior</strong>.
          </div>}
        />
      </div>
    );
  }
}

class TargetingTab extends Component {
  render() {
    return (
      <div>
        <AccountTargetingSettingsForm 
          id={this.props.accountId}
          account={this.props.account}
          belowVisualDisplay={<div className="below-visual-display">
            <i className="fas fa-arrow-turn-up" />This is a preview of your <strong>on-site</strong> behavior defaults. To edit the style for a specific survey only select the <Link to={`/a/${encode(this.props.accountId)}?tab=surveys`}>survey</Link> then click <strong>Settings → Targeting</strong>.
          </div>}
        />
      </div>
    );
  }
}

function getSwitchState(tab) {
  if (tab === 'widget') {
    return 'one';
  }
  if (tab === 'page') {
    return 'two';
  }
  if (tab === 'email') {
    return 'three';
  }
  if (tab === 'sms') {
    return 'four';
  }
}

class DisplayTab extends Component {
  constructor(props) {
    super(props);
    const tab = props.params.type || 'widget';
    this.props.setParam('type', tab);
    this.state = { tab };
  }

  onClick(tab, e) {
    this.props.setParam('type', tab);
    this.setState({ tab });
  }

  render() {
    let tabs = (
      <div className={`big-switch squared quad ${getSwitchState(this.state.tab)}`}>
        <div className={`${this.state.tab === 'widget' ? 'active' : ''}`} onClick={this.onClick.bind(this, 'widget')}><i className="fas fa-poll" />On-Site</div>
        <div className={`${this.state.tab === 'page' ? 'active' : ''}`} onClick={this.onClick.bind(this, 'page')}><i className="fas fa-link" />Page</div>
        <div className={`${this.state.tab === 'email' ? 'active' : ''}`} onClick={this.onClick.bind(this, 'email')}><i className="fas fa-envelope" />Email</div>
        <div className={`${this.state.tab === 'sms' ? 'active' : ''}`} onClick={this.onClick.bind(this, 'sms')}><i className="fas fa-sms" />SMS</div>
      </div>
    );

    let form = <AccountDisplaySettingsForm
      account={this.props.account}
      settings={this.props.account.displaySettings}
      tabs={tabs}
      belowVisualDisplay={<div className="below-visual-display">
        <i className="fas fa-arrow-turn-up" />This is a preview of your <strong>on-site</strong> design defaults. To edit the style for a specific survey only select the <Link to={`/a/${encode(this.props.accountId)}?tab=surveys`}>survey</Link> then click <strong>Settings → Design</strong>.
      </div>}
  />

    if (this.state.tab === 'email') {
      form = <AccountEmailDisplaySettingsForm
      account={this.props.account}
      settings={this.props.account.emailDisplaySettings}
      tabs={tabs}
      belowVisualDisplay={<div className="below-visual-display">
        <i className="fas fa-arrow-turn-up" />This is a preview of your <strong>email</strong> design defaults. To edit the email design for a specific survey only select the <Link to={`/a/${encode(this.props.accountId)}?tab=surveys`}>survey</Link> then click <strong>Settings → Design</strong>.
      </div>}
      />;
    }

    if (this.state.tab === 'sms') {
      form = <AccountSmsDisplaySettingsForm
      account={this.props.account}
      settings={this.props.account.smsDisplaySettings}
      tabs={tabs}
      belowVisualDisplay={<div className="below-visual-display">
        <i className="fas fa-arrow-turn-up" />This is a preview of your <strong>SMS</strong> design defaults. To edit the SMS design for a specific survey only select the <Link to={`/a/${encode(this.props.accountId)}?tab=surveys`}>survey</Link> then click <strong>Settings → Design</strong>.
      </div>}
      />;
    }

    if (this.state.tab === 'page') {
      form = <AccountPageDisplaySettingsForm
      account={this.props.account}
      settings={this.props.account.pageDisplaySettings}
      tabs={tabs}
      belowVisualDisplay={<div className="below-visual-display">
        <i className="fas fa-arrow-turn-up" />This is a preview of your <strong>page</strong> design defaults. To edit the style for a specific survey only select the <Link to={`/a/${encode(this.props.accountId)}?tab=surveys`}>survey</Link> then click <strong>Settings → Design</strong>.
      </div>}
      />;
    }

    return (
      <div style={{ minWidth: 1100 }}>
        { form }
      </div>
    );
  }
}

class LanguageTab extends Component {
  render() {
    return (
      <div>
        <AccountLanguageSettingsForm
          account={this.props.account}
        />
      </div>
    );
  }
}

class EmbedTab extends Component {
  render() {
    return (
      <div className="centered-embed">
        <EmbedCode bottom={true} account={this.props.account} accounts={this.props.accounts} user={this.props.user} noMargin={true} />

        <div className="below-card-info center" style={{ paddingLeft: 0 }}>
          <p>Need help installing? Check out our <a href={`https://docs.zigpoll.com`} rel="noopener noreferrer">Documentation</a>.</p>
        </div>
      </div>
    );
  }
}

class VisualDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = { currentIdx: 0, active: true, prevIndexes: [] };
  }

  componentDidUpdate(prevProps, prevState) {  
    if (!_.isEqual(prevProps.slides, this.props.slides)) {
      this.reset();
    }
  }

  next() {
    this.state.prevIndexes.push(this.state.currentIdx);
    console.log(this.state.currentIdx);
    this.setState({ currentIdx: this.state.currentIdx + 1 });
  }

  prev() {
    const idx = this.state.prevIndexes.pop();
    if (idx !== undefined) {
      this.setState({ currentIdx: idx });
    }
  }

  goto(slideId) {
    let idx = undefined;
    this.props.slides.forEach(({ _id }, i) => {
      if (_id === slideId) {
        idx = i;
      }
    })
    if (idx === undefined) { return this.next(); }

    this.state.prevIndexes.push(this.state.currentIdx);
    this.setState({ currentIdx: idx });
  }

  reset() {
    this.setState({ currentIdx: 0, prevIndexes: [] });
  }

  render() {
    let showEmbed = !this.props.loading;

    const slides = this.props.slides || [];
    let title, subtitle, showTitle, showLoading;
    let renderSlidesWarning = (slides.length === 0) && !this.props.loading;
    if (renderSlidesWarning) {
      subtitle = <div><i className="fas fa-info-circle warning"/>There are no slides for this survey.</div>
      showEmbed = false;
      showTitle = true;
    }
    if (this.props.loading) {
      // subtitle = <div className="loading">Loading...</div>;
      showEmbed = false;
      showLoading = true;
    }

    let pollPreview = <Embed 
      { ...this.props }
      toggleActive={() => { this.setState({ active: !this.state.active})}}
      active={this.state.active}
      next={this.next.bind(this)}
      prev={this.prev.bind(this)}
      goto={this.goto.bind(this)}
      currentIdx={this.state.currentIdx}
      isEmbed={this.props.pollType === 'embed'}
    />

    if (['widget', 'embed', 'api'].indexOf(this.props.pollType) === -1) {
      pollPreview = <PagePreview 
        { ...this.props }
        toggleActive={() => { this.setState({ active: !this.state.active})}}
        active={this.state.active}
        next={this.next.bind(this)}
        prev={this.prev.bind(this)}
        goto={this.goto.bind(this)}
        currentIdx={this.state.currentIdx}
      />
    }

    if (!showEmbed) {
      pollPreview = null;
    }

    return (
      <div className="visual-display">
        <BrowserFrame 
          title={title}
          subtitle={subtitle}
          showTitle={showTitle}
          showLoading={showLoading}
        />
        { pollPreview }
      </div>      
    );
  }
}

class PollTab extends Component {
  constructor(props) {
    super(props);
    this.state = { showQuickDeleteModal: false, showQuickArchiveModal: false, selectedPollIdx: 0 };
  }

  componentDidMount() {
    if (this.props.account.polls.length === 0) { return; }

    const selectedPollId = this.props.account.polls[0]._id;
    this.props.fetchPollWithSlides(selectedPollId).then(() => {
      this.forceUpdate();
    });
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.account.polls, prevProps.account.polls)) {
      if (this.props.account.polls.length === 0) { return; }
      const selectedPollId = this.props.account.polls[this.state.selectedPollIdx]._id;
      this.props.fetchPollWithSlides(selectedPollId).then(() => {
        this.forceUpdate();
      });
    }
  }

  closeModal() {
    this.setState({ showQuickDeleteModal: false, showQuickArchiveModal: false });
    this.onConfirm = undefined;
  }

  render() {
    let loadingPoll = true;

    const selectedPollObj = this.props.account.polls[this.state.selectedPollIdx] || {};
    const selectedPollId = selectedPollObj._id;
    const selectedPoll = this.props.polls[selectedPollId] || { settings: {} };

    let belowVisualDisplay = null;
    let pollType = 'widget';
    let pageDisplaySettings;
    let languageSettings;

    if (selectedPoll._id) {
      loadingPoll = false;
      if (selectedPoll.settings && selectedPoll.settings.selector) {
        pollType = 'embed';
      }
      if (selectedPoll.settings && selectedPoll.settings.apiOnly) {
        pollType = 'link';
      }
      if (selectedPoll.settings && selectedPoll.settings.shopifyAbandonedCheckout) {
        pollType = 'abandoned-checkout';
      }
      if (selectedPoll.settings && selectedPoll.settings.shopifyAbandonedCheckout) {
        pollType = 'email';
      }
      if (selectedPoll.settings && selectedPoll.settings.shopifyWebhooks) {
        pollType = 'email';
      }
      if (selectedPoll.settings && selectedPoll.settings.sms) {
        pollType = 'sms';
      }
      if (selectedPoll.settings && selectedPoll.settings.pollType === 'api') {
        pollType = 'api';
      }

      pageDisplaySettings = this.props.account.pageDisplaySettings || generatePageDisplaySettings({ account: this.props.account, poll: selectedPoll});
      if (selectedPoll && selectedPoll.pageDisplaySettings) {
        pageDisplaySettings = selectedPoll.pageDisplaySettings;
      }

      languageSettings = selectedPoll.languageSettings;

      belowVisualDisplay = <div className="below-visual-display"><i className="fas fa-arrow-turn-up" />A preview of the survey titled: <Link to={`/dashboard/a/${encode(this.props.accountId)}/p/${encode(selectedPoll._id)}`}>{selectedPoll.title}</Link> is printed above. <Link to={`/a/${encode(this.props.accountId)}/p/${encode(selectedPoll._id)}?tab=slides`}>Click here</Link> to make edits or <a href={`/preview/${this.props.accountId}/${selectedPoll._id.toString()}`} target="_blank">click here to try a full demo</a>.</div>
    }

    const selectedSlides = selectedPoll.slides || [];
    let selectedSettings = this.props.account.displaySettings;
    if (selectedPoll.displaySettings) {
      selectedSettings = selectedPoll.displaySettings;
    }

    return (
      <div>
        <div className="survey-page-split">
          <ActivePolls
            accountId={this.props.accountId}
            account={this.props.account}
            toggleVisibility={this.props.toggleVisibilityById.bind(this)}
            selectedPollIdx={this.state.selectedPollIdx}
            onClick={(idx) => {
              const selectedPollId = this.props.account.polls[idx]._id;
              this.setState({ selectedPollIdx: idx });
              this.props.fetchPollWithSlides(selectedPollId).then(() => {
                this.forceUpdate();
              });
            }}
            duplicate={this.props.duplicatePollById.bind(this)}
            delete={(_id) => {
              this.setState({ showQuickDeleteModal: true });
              this.onConfirm = () => {
                this.props.deletePollById(_id)
                this.onConfirm = undefined;
              };
            }}
            archive={(_id) => {
              this.setState({ showQuickArchiveModal: true });
              this.onConfirm = () => {
                this.props.archivePollById(_id)
                this.onConfirm = undefined;              
              };
            }}
          />
          { this.props.account.polls.length > 0 && <div className="preview-container-wrapper">
            <SurveyPreviewHeader 
              account={this.props.account}
              poll={selectedPoll}
            />
            <div className="preview-container">
              <VisualDisplay
                slides={[ ...selectedSlides ]}
                loading={loadingPoll}
                pollType={pollType}
                pageDisplaySettings={pageDisplaySettings}
                languageSettings={languageSettings}
                hideCloseButton={selectedPoll.settings.hideCloseButton}
                hideXButton={selectedPoll.settings.hideXButton}
                {...selectedSettings}
              />{belowVisualDisplay} 
            </div>
          </div>
          }
        </div>

        <HeaderButtons>
          <Link className="create" to={`/a/${encode(this.props.accountId)}/p/create`}>New Survey</Link>
        </HeaderButtons>

        <Modal 
          isOpen={this.state.showQuickArchiveModal}
          onRequestClose={this.closeModal.bind(this)}
        >
          <div className="frame">
            <div className="close" onClick={this.closeModal.bind(this)} />
            <div className="title">Are you sure?</div>
            <div className="content">
              <div className="subtitle">This will archive the survey so it is only visible in your dashboard. You can undo this later.</div>
              <div className="actions">
                <button className="positive" onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  this.onConfirm();
                  this.closeModal();
                }}>Yes</button>
                <button className="negative" onClick={this.closeModal.bind(this)}>No</button>
              </div>
            </div>
          </div>
        </Modal>

        <DeleteConfirm 
          title="Are you sure?"
          subtitle="This will permanently erase the survey, its slides, and responses forever."
          show={this.state.showQuickDeleteModal}
          onConfirm={this.onConfirm}
          onCancel={() => {
            this.setState({ showQuickDeleteModal: false });
          }}
        />
      </div>
    );
  }
}

class CreateAccount extends Component {
  constructor(props) {
    super(props);

    if (!this.props.account && this.props.accountId) {
      this.props.fetchAccount(this.props.accountId);
    } else {
      
    }
  }

  componentDidMount() {
    if (!this.props.tab) {
      this.props.setParam('tab', 'general');
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.accountId !== prevProps.accountId) {
      this.props.fetchAccount();
    }
    if (!this.props.tab) {
      this.props.setParam('tab', 'general');
    }
  }

  render() {
    if (!this.props.account || !this.props.tab) {
      return (
        <ColumnLoading />
      );
    }

    let content = null;
    if (this.props.tab === 'general') {
      content = <GeneralTab {...this.props} />;
    }

    if (this.props.tab === 'behavior') {
      content = <VisibilityTab {...this.props} />;
    }

    if (this.props.tab === 'targeting') {
      content = <TargetingTab {...this.props} />;
    }

    if (this.props.tab === 'design') {
      content = <DisplayTab {...this.props} />
    }

    if (this.props.tab === 'language') {
      content = <LanguageTab {...this.props} />
    }

    if (this.props.tab === 'embed') {
      content = <EmbedTab {...this.props} />
    }

    if (this.props.tab === 'surveys') {
      content = <PollTab {...this.props} />
    }

    return (
      <ColumnLayout
        title={`Account → ${capitalize(this.props.tab)}`}
        className="account"
      >

        { content }

        <LoadingIndicator loading={this.props.accounts.loading} />
      </ColumnLayout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const accounts = state.accounts || {};
  const account = accounts[accountId];
  const polls = state.polls;
  const user = state.user;

  return {
    accountId,
    accounts,
    account,
    polls,
    tab: getTab(ownProps.location.search),
    params: getParams(ownProps.location.search),
    user,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AccountActions, ...PollActions, ...RouterActions }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateAccount));
