import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Elements, CardElement, injectStripe } from 'react-stripe-elements';
import { AuthSubmitButton } from './Buttons'
import { loadContactUsSurvey } from '../utils'

import * as UserActions from '../actions/Users';
import * as FlashNotificationsActions from '../actions/FlashNotifications';

import $ from 'jquery';

class CardSection extends Component {
  render() {
    let zoom = $('html').css('zoom') || 1;
    zoom = parseFloat(zoom);

    return <div>
      <label>{ this.props.label || 'Add New Credit Card' }</label>
      <CardElement
        style={{
          base: {
            fontSize: `${18 * 1}px`,
            color: '#343538',
            fontWeight: '400',
            fontFamily: 'proxima-nova, Helvetica, sans-serif',
            lineHeight: `${50 * 1}px`,
            '::placeholder': {
              opacity: .5,
            },
          },
          invalid: {
            color: '#F26c57',
          },
          complete: {
            color: '#5fbc53'
          }
        }}
      />
    </div>
  }
}

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSubmit(e) {
    e.stopPropagation();
    e.preventDefault();

    this.setState({ submitting: true })
    this.setState({ errorMessage: undefined });

    this.props.stripe.createToken({ name: this.props.user.name })
      .then(({ token, error }) => {
        console.log(token);

        if (error) {
          if (error.type === 'validation_error') {
            this.props.flash(error.message);
            this.setState({ errorMessage: error.message });
          } else {
            this.props.flash(error.message);
          }
        } else if (token) {
          if (this.props.onSubmit) {
            this.props.onSubmit(token);
          } else {
            this.props.addCard(token);
          }
        }
      });
  };

  componentDidUpdate(prevProps) {
    if ((this.props.loading !== prevProps.loading) && this.props.loading === false) {
      if (this.state.submitting) {
        this.setState({ submitting: false });
        this.props.cancel();
      }
    }
  }

  render() {
    return (
      <form className={`card ${this.props.user.updating ? 'loading' : ''}`} onSubmit={this.handleSubmit.bind(this)}>

        <CardSection
          label={this.props.label}
        />

        <AuthSubmitButton
          title="Save Card"
          disabled={this.props.loading}
        />

        <button className="cancel" onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          this.props.cancel();
        }}>Cancel</button>

        { this.props.contactUs && <button className="contact" onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          loadContactUsSurvey(this.props.user);

        }}>Contact Us</button>}
      </form>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    loading: state.user.updating || ownProps.loading,
    user: state.user
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...UserActions, ...FlashNotificationsActions }, dispatch);
}

const SmartForm = injectStripe(connect(mapStateToProps, mapDispatchToProps)(Form));

export default class PaymentCaptureForm extends Component {
  render() {
    if (!this.props.show) {
      return null;
    }

    return (
      <Elements
        fonts={[
          { cssSrc: 'https://use.typekit.net/fud0auo.css' }
        ]} >
        <SmartForm
          onSubmit={this.props.onSubmit}
          label={this.props.label}
          cancel={this.props.cancel}
          loading={this.props.loading}
          contactUs={this.props.contactUs}
          user={this.props.user}
        />
      </Elements>
    );
  }
}
