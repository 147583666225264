/* User Reducer */
import _ from 'lodash';

import { 
  CREATED_USER,
  CREATING_USER,
  FETCHED_USER,
  FETCHING_USER,
  UPDATED_USER,
  UPDATING_USER,
  DELETED_USER,
  DELETING_USER,
  RESET_USER,
  LOGGED_OUT,
  FETCHING_STRIPE_DATA,
  FETCHED_STRIPE_DATA,
  FETCHING_AFFILIATE_DATA,
  FETCHED_AFFILIATE_DATA,
} from '../actions/Users';

import { CREATED_ACCOUNT, UPDATED_ACCOUNT, DELETED_ACCOUNT } from '../actions/Accounts';

import ReactGA from 'react-ga'

export default function user(state = {}, action) {
  switch (action.type) {
    case CREATING_USER:
    case FETCHING_USER:
      return { ...state, loading: true }
    case CREATED_USER:
    case FETCHED_USER:
      /* Hack to make it easier to print currency */
      if (action.user && action.user.currency) {
        window.userCurrency = action.user.currency;
      }
      ReactGA.set({ user_id: action.user._id, userId: action.user._id });
      return { ...action.user };
    case UPDATING_USER:
    case DELETING_USER:
      return { ...state, updating: true }
    case UPDATED_USER:
      return { ...state, ...action.user, updating: false };
    case FETCHING_STRIPE_DATA:
    case FETCHING_AFFILIATE_DATA:
      return { ...state, fetching: true };
    case FETCHED_STRIPE_DATA:
    case FETCHED_AFFILIATE_DATA:
      return { ...state, fetching: false };
    case CREATED_ACCOUNT:
    case UPDATED_ACCOUNT:
      const accounts = state.accounts ? [ ...state.accounts ] : [];
      const account = { _id: action.account._id, title: action.account.title };

      if (!account.title) {
        return state;
      }

      const idx = _.findIndex(accounts, o => o._id === action.account._id);

      if (idx === -1) {
        accounts.push(account);
      } else {
        accounts[idx] = account;
      }

      return { ...state, accounts };
    case DELETED_ACCOUNT:
      const user = { ...state }
      const userAccounts = [];

      user.accounts.forEach((account) => {
        if (typeof account === 'object') {
          userAccounts.push(account._id);
        } else {
          userAccounts.push(account);
        }
      })

      user.accounts = _.remove(userAccounts, a => a !== action.accountId);

      return { ...user }  
    case RESET_USER:
    case LOGGED_OUT:
    case DELETED_USER:
      ReactGA.set({ user_id: undefined, userId: undefined });
      return {};
    default:
      return state;
  }
}
