import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import _ from 'lodash';

import { decode, getCards, getSubscription, getActiveCard, getSubscriptionCard, loadContactUsSurvey } from '../utils';
import { plans, planDetails } from '../settings';

import * as AccountActions from '../actions/Accounts';
import * as UserActions from '../actions/Users';

import SectionHeader from './SectionHeader';
import { AuthSubmitButton } from './Buttons';
import PromotionInput from './PromotionInput'
import AppliedPromotions from './AppliedPromotions';

class AccountSubscriptionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planId: this.props.planId || plans[0].id
    }

    this.initialState = this.state;
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps) {

  }

  hasChanged() {
    return !_.isEqual({ planId: this.state.planId }, this.initialState);
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    this.props.changePlan(this.state.planId);
  }

  onChange(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ planId: e.target.value });
  }

  render() {
    const planId = this.state.planId;
    const plan = _.find(plans, (plan) => plan.id === planId) || plans[0];

    let customPlan = null;
    if (this.props.account && this.props.account.customPlan) {
      const { id, label, price } = this.props.account.customPlan;
      customPlan = (<option value={id} key={id}>{`${label} : ${ price === 0 ? 'FREE' : `$${price} per month` }`}</option>);
    }

    let details = null;
    if (planDetails[plan.key] && !customPlan) {
      details = <div className="plan-details">
        <br/>
        <label>Subscription Details</label>
        <ul>{planDetails[plan.key].map((item) => {
          return <li>{ item }</li>
        })}</ul>
        <br/>
      </div>
    }

    return (<form className={`subscription-form ${this.props.accounts.loading ? 'loading' : ''}`}>
      <SectionHeader
        title=<span><i class="fas fa-file-invoice-dollar"></i>Subscription Plan</span>
        subtitle="Edit the subscription plan for this account."
        tooltip="The default credit card will be used if not otherwise specified."
      />

      <div className="subscription-details">
        <AppliedPromotions />

        <div className="card" style={{ marginBottom: 25, paddingBottom: 0, overflow: 'hidden' }}>
          <label>Subscription Plan</label>

          <div className="select-wrapper">
            <select value={planId} onChange={this.onChange.bind(this)}>
              { plans.map(({ label, id, price }) => {
                const str = `${label} : ${ price === 0 ? 'FREE' : `$${price} per month` }`;
                return (<option value={id} key={id}>{str}</option>)
              })}

              { customPlan }
            </select>
          </div>

          { details }

          <div className="below">To change plans, select a new plan from the dropdown list above. All changes take effect immediately.<br/><br/>You can learn more about our subscription plans <a target="_blank" rel="noopener noreferrer" href="https://docs.zigpoll.com/subscription-plans">here</a>.<br/><br/>If no plan fits your needs, please <a href="https://www.zigpoll.com/contact" target="_blank" onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            loadContactUsSurvey(this.props.user);
          }}>contact us</a> and we will work with you to find a solution.</div>
        </div>

        <PromotionInput />

        <div className="card actions">
          <AuthSubmitButton
            title="Update Plan"
            disabled={this.props.accounts.loading ||  !this.hasChanged()}
            onClick={this.onSubmit.bind(this)}
          />

          <div className="">
            <div>Not sure what you need?</div>
            <a target="_blank" rel="noopener noreferrer" href="https://www.zigpoll.com/#pricing-plans">See all plans</a>
          </div>
        </div>

      </div>
    </form>)
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const accounts = state.accounts || {};
  const account = accounts[accountId];
  const user = state.user;

  let planId;
  if (account.plan) {
    planId = account.plan.id;
  }

  return {
    accountId,
    accounts,
    account,
    planId,
    user
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AccountActions, ...UserActions }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountSubscriptionForm));

