import React, { Component } from 'react';

import _ from 'lodash';
import $ from 'jquery';

import BrowserFrame from '../components/BrowserFrame';
import Embed from '../components/Embed';
import PagePreview from '../components/PagePreview';

import { getSlideLabel, encode } from '../utils';

export default class VisualDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = { active: true };
    this.scroll = _.throttle(this.scroll.bind(this), 0, { leading: true, trailing: true });
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ active: true });
    }, 100);
  
    $('.foreground').on('scroll.slide', this.scroll);
    this.scroll();
  }

  componentWillUnmount() {
    $('.foreground').off('scroll.slide', this.scroll)
  }

  scroll() {
    // let $el = $('.visual-display-wrapper').first();
    
    // let zoom = $('html').css('zoom') || 1;
    // zoom = parseFloat(zoom);

    // let t = $('.slide-form-wrapper')[0].getBoundingClientRect().y - document.documentElement.getBoundingClientRect().y * zoom;
    // let st = 0;

    // let b = $('.slide-form-wrapper .slide-form').height();
    // let atBottom = ((st - t) + 680 + 80) >= b;

    // if ( (t) < 80 && !atBottom ) {
    //   $el.css('transform', `translateY(${-t + 80}px)`);
    // } else if (atBottom) {
    //   $el.css('transform', `translateY(${b - 680}px)`);
    // } else {
    //   $el.css('transform', `translateY(0px)`);
    // }
  }

  render() {
    let browserTitle = '';
    let browserSubtitle = '';

    if (this.props.preview) {
      browserTitle = `Previewing a "${getSlideLabel(this.props.preview)}" Slide.`;
      browserSubtitle = 'Check out the example below.'
    }

    let previewType = <Embed 
      { ...this.props }
      rewardBanner={this.props.rewardBanner}
      toggleActive={() => { this.setState({ active: !this.state.active})}}
      active={this.state.active}
      isEmbed={this.props.pollType === 'embed'}
    />

    if (['widget', 'embed', 'api'].indexOf(this.props.pollType) === -1) {
      previewType = <PagePreview
        {...this.props}
        rewardBanner={this.props.rewardBanner}
      />
    }

    let belowVisualDisplay = <div className="below-visual-display"><i className="fas fa-arrow-turn-up" />A preview of this slide is printed in the box above. Make any edits in the form to the left.</div>
    let previewLink = null;
    if (this.props.slides && this.props.slides[0] && !this.props.createPage) {
      const slide = this.props.slides[0];
      const accountId = encode(this.props.accountId);
      const pollId = encode(slide.pollId); 

      belowVisualDisplay = <div className="below-visual-display"><i className="fas fa-arrow-turn-up" />A preview of this slide is printed in the box above. Make any edits in the form to the left.<br/><br/><a href={`/a/${accountId}/p/${pollId}?tab=language`} target="_blank">Click here</a> to translate this slide into additional languages.</div>

      if (this.props.poll) {
        previewLink = (<span><a target='_blank' rel="noopener noreferrer" title="Preview your survey" className="poll-preview" href={`/preview/${this.props.accountId}/${this.props.poll._id.toString()}`}>Click here</a> for a full preview.</span>);
      }
    }

    return (
    <div className="visual-display-wrapper-container">
      <div className="section-header small no-margin first">
        <div className="top">
          <div className="section-subtitle"><i className="fas fa-magnifying-glass" />Slide Preview</div>
        </div>
        <div className="section-description">Slide representation below. {previewLink}</div>
      </div>

      <div className="visual-display-wrapper">
        <div className="visual-display">
          <BrowserFrame 
            title={browserTitle}
            subtitle={browserSubtitle}
          />
          { previewType }
        </div>
        { belowVisualDisplay }
      </div>
    </div>);
  }
}