import React, { Component } from 'react'
import { ChromePicker } from 'react-color'
import tinycolor from 'tinycolor2';

class ColorPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayColorPicker: false
    }
  };

  handleClick() {
    if (this.props.onOpen && !this.state.displayColorPicker) {
      this.props.onOpen();
    }

    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose(e) {
    this.setState({ displayColorPicker: false })

    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  handleChange(color) {
    this.props.onChange(color);
  };

  render() {
    let color = "#ff0000";
    if (this.props.color) {
      if (typeof this.props.color === 'string') {
        color = this.props.color;
      } else {
        color = tinycolor(this.props.color).toHex8String();
      }
    }

    return (
      <div
        className={`zigpoll-color-picker color-picker ${this.state.displayColorPicker ? 'active' : ''}`}
      >
        <div className="input" onClick={ this.handleClick.bind(this) }>
          <div className="swatch">
            <div style={{ background: color }} />
          </div>
          <div className="hex">{ color }</div>
        </div>
        <div className="picker">
          <div className="overlay" onClick={ this.handleClose.bind(this) } /> 
          <ChromePicker
            color={ color }
            onChange={(color) => { this.setState({ color: tinycolor(color.rgb).toHex8String() }); }}
            disableAlpha={this.props.disableAlpha === undefined ? true : this.props.disableAlpha}
            onChangeComplete={ this.handleChange.bind(this) }
          />
        </div>
      </div>
    )
  }
}

export default ColorPicker