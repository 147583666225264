import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ColumnLoading from './ColumnLoading';

import ColumnLayout from '../components/ColumnLayout';
import HeaderButtons from '../components/HeaderButtons';

import SlideInfo from '../components/SlideInfo';
import Tooltip from '../components/Tooltip';
import Diff from '../components/Diff';
import ExportCsvModal from '../components/ExportCsvModal'
import PollInfo from '../components/PollInfo';
import VisibilityToggle from '../components/VisibilityToggle';

import * as DashboardActions from '../actions/Dashboard';
import * as ParticipantsActions from '../actions/Participants';
import * as PollActions from '../actions/Polls';

import { decode, encode, renderNumber, renderAccountTitle, getSentimentClass, getSentimentTitle, getPollType, truncate, generatePageDisplaySettings, getPollDisplayType, getDisplaySettings } from '../utils';

import qs from 'query-string';
import _ from 'lodash';
import $ from 'jquery';

import BrowserFrame from '../components/BrowserFrame';
import Embed from '../components/Embed';
import PagePreview from '../components/PagePreview';
import SurveyPreviewHeader from '../components/SurveyPreviewHeader';

class DashboardVisualDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = { active: true };
  }

  componentDidMount() {
    $(window).on('resize.display', this.resize);
    this.resize();
    window.pollState = 'before-submission';
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.showLink !== this.props.showLink) {
      this.resize();
    }

    if ((prevProps.active !== this.props.active) && this.props.active) {
      this.resize();
    }

    return null;
  }

  componentWillUnmount() {
    // $(window).off('resize.display', this.resize)
  }

  resize() {
    $('.poll-card').css('min-height', $(window).height());
  }

  next() {
    if (this.props.idx === 0) {
      this.props.setIdx(1);      
    } else {
      this.props.setIdx(this.props.idx + 1);
    }
  }

  prev() {
    this.props.setIdx(this.props.idx - 1);
  }

  goto(slideId) {
    let idx = undefined;
    this.props.slides.forEach(({ _id }, i) => {
      if (_id === slideId) {
        idx = i;
      }
    })
    if (idx === undefined) { return this.next(); }

    this.props.setIdx(idx+1);
  }

  reset() {
    this.props.setIdx(0);
  }

  render() {
    let slides = this.props.slides || [];

    let title, subtitle, showTitle, showLoading;
    let renderSlidesWarning = (slides.length === 0) && !this.props.loading;
    let showEmbed = true;
    if (renderSlidesWarning) {
      subtitle = <div><i className="fas fa-info-circle warning"/>There are no slides for this survey.</div>
      showEmbed = false;
      showTitle = true;
    }
    if (this.props.loading) {
      showLoading = true;
      showEmbed = false;
    }

    let previewType = <Embed
      {...this.props}
      scalePoll={false}
      slides={slides}
      next={this.next.bind(this)}
      prev={this.prev.bind(this)}
      goto={this.goto.bind(this)}
      showHidden={true}
      currentIdx={this.props.idx > 0 ? this.props.idx - 1 : 0}
      pollState={window.pollState}
      isEmbed={this.props.pollType === 'embed'}
    />

    if (['widget', 'embed', 'api'].indexOf(this.props.pollType) === -1) {
      previewType = <PagePreview
        {...this.props}
        scalePoll={false}
        slides={slides}
        next={this.next.bind(this)}
        prev={this.prev.bind(this)}
        goto={this.goto.bind(this)}
        showHidden={true}
        currentIdx={this.props.idx > 0 ? this.props.idx - 1 : 0}
        pollState={window.pollState}
      />
    }

    let belowVisualDisplay = this.props.belowVisualDisplay || null;

    if (!showEmbed) {
      previewType = null;
    }

    return (
    <div className="visual-display-wrapper">
      <div className="visual-display" ref={this.el}>
        <BrowserFrame 
          title={title}
          subtitle={subtitle}
          showTitle={showTitle}
          showLoading={showLoading}
        />

        { previewType }
      </div>
      { belowVisualDisplay }
    </div>
    );
  }
}

class PollCard extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps._id && (prevProps._id !== this.props._id)) {
      this.props.setIdx(0);
    }
  }

  next() {
    this.props.setIdx(this.props.idx + 1)
  }

  prev() {
    this.props.setIdx(this.props.idx - 1)
  }

  renderSlide() {
    const props = this.props;

    /* Poll Summary Card */
    if (this.props.idx === 0) {
      return (<PollInfo {...props} />)
    }

    const slide = this.props.slides[this.props.idx - 1];
    return (<SlideInfo slide={slide} diffs={this.props.diffs} slideIdx={this.props.idx} slides={this.props.slides || []} slideCount={this.props.slides.length} accountId={this.props.accountId} pollId={this.props._id} />)
  }  

  render() {
    const loading = (
      <div className="poll-card loading">
        <div>
          <div className="poll-content">
            <div className="section">
              <p>Loading...</p>
            </div>
          </div>
        </div>
      </div>
    );

    if (this.props.slides && this.props.idx !== 0 && !this.props.slides[this.props.idx-1]) {
      this.props.setIdx(0);
      return loading;
    }

    if (!this.props._id) {
      return loading;
    }

    const props = this.props;

    let editLink = `/a/${encode(props.accountId)}/p/${encode(props._id)}?tab=general`;
    if (!props.slides || props.slides.length === 0) {
      editLink = `/a/${encode(props.accountId)}/p/${encode(props._id)}?tab=slides`;
    }
    const isVisible = props.isVisible !== false;

    let next = null;

    if (this.props.idx !== props.slides.length) {
      next = (<button onClick={this.next.bind(this)} className="next">Next</button>);
    }

    let back = null;
    if (this.props.idx !== 0) {
      back = (<button onClick={this.prev.bind(this)} className="back">Back</button>);
    }

    if (this.props.idx !== 0) {
      editLink = `/a/${encode(props.accountId)}/p/${encode(props._id)}/s/${encode(this.props.slides[this.props.idx-1]._id)}?tab=details`
    }

    return <div
      className={`poll-card poll-dashboard ${isVisible ? 'active' : 'inactive' }`}
    >
      <div>

        <div className="actions">
          { next }
          <Link className="edit-link" to={editLink}>Edit {this.props.idx === 0 ? 'Survey' : 'Slide'}</Link>
          { back }
        </div>

        <div className="poll-content">
          { this.renderSlide() }
        </div>

        <div className="actions footer">
          { next }
          { back }
        </div>
      </div>
    </div>
  }
};

class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    let hasSetSlideIdx = false;
    /* if you already have the data, run it - otherwise do it after fetching */
    if (this.props.slideId && this.props.poll) {
      const slides = this.props.poll.slides || [];
      const idx = _.findIndex(slides, ({ _id }) => _id == this.props.slideId);

      if (idx !== -1) {
        hasSetSlideIdx = true;
        this.props.setIdx(idx+1);
      }
    }

    this.props.fetchPollData(this.props.pollId)
    .then(() => {
      if (hasSetSlideIdx) { return; }

      if (this.props.slideId && this.props.poll) {
        const slides = this.props.poll.slides || [];
        const idx = _.findIndex(slides, ({ _id }) => _id == this.props.slideId);
        this.props.setIdx(idx+1);

        if (idx !== -1) {
          this.props.setIdx(idx+1);
        }
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.pollId !== prevProps.pollId) {
      return this.props.fetchPollData(this.props.pollId);
    }
  }

  export() {
    this.setState({ showExportModal: true });
  }

  render() {
    let account;
    let loaded;

    if (!this.props.poll || !this.props.account) {
      return <ColumnLoading />
    }

    let breadcrumbs = (
      <div id="breadcrumbs">
        <div className="wrapper">
          <ul className="crumbs">
            <li><Link to={`/a/${encode(this.props.account._id)}`} title='Account'>Account</Link></li>
          </ul>
        </div>
      </div>
    );

    breadcrumbs = null;

    const pollDiff = this.props.diffs[this.props.poll._id] || {};

    const sentimentStats = (
      <div className="sentiment">
        <label>Sentiment<Tooltip>The average sentiment of answers to this survey.</Tooltip></label>
        <p title={this.props.poll.responseCount || 0} className={getSentimentClass(this.props.poll.sentiment)}>{getSentimentTitle(this.props.poll.sentiment)}</p>
      </div>
    );

    let pageDisplaySettings = this.props.account.pageDisplaySettings || generatePageDisplaySettings(this.props);
    if (this.props.poll && this.props.poll.pageDisplaySettings) {
      pageDisplaySettings = this.props.poll.pageDisplaySettings;
    }

    let belowVisualDisplay = <div className="below-visual-display"><i className="fas fa-arrow-turn-up" />A preview of the survey titled: <Link to={`/dashboard/a/${encode(this.props.accountId)}/p/${encode(this.props.poll._id)}`}>{this.props.poll.title}</Link> is printed above. <Link to={`/a/${encode(this.props.accountId)}/p/${encode(this.props.poll._id)}?tab=slides`}>Click here</Link> to make edits or <a href={`/preview/${this.props.accountId}/${this.props.poll._id.toString()}`} target="_blank">click here to try a full demo</a>.</div>

    return (
      <ColumnLayout
        title="Survey → Dashboard"
        className="dashboard"
        graphics={false}
        breadcrumbs={breadcrumbs}
        pageType="poll-dashboard"
      >
        <div className="split big account-display">
          <div>
            <div className={`account-stats no-request-counter ${sentimentStats ? 'wide' : ''}`}>
              <div className="section-header small no-margin"><div className="top"><div className="section-subtitle">{ getPollType(this.props.activePoll) }{this.props.poll.title}</div>
              </div>
              <div className="section-description">View your survey responses, response rate, and AI insights below.</div>
              </div>
              <div className="card">
                <div className="stats">
                  <div className="response-count">
                    <label><div className="pill">open-ended</div>Responses<Tooltip>All the open-ended responses you have received under this survey.</Tooltip></label>
                    <Link to={`/responses/a/${encode(this.props.account._id)}/p/${encode(this.props.poll._id)}`}><p title={this.props.poll.responseCount || 0}>{ renderNumber(this.props.poll.responseCount, '--') }<Diff value={pollDiff.responsesDiff} /></p></Link>
                  </div>
                  <div className="vote-count">
                    <label><div className="pill">fixed-choice</div>Responses<Tooltip>Total number of fixed-choice responses submitted to this survey.</Tooltip></label>
                    <Link to={`/responses/a/${encode(this.props.account._id)}/p/${encode(this.props.poll._id)}`} ><p title={this.props.poll.voteCount || 0}>{ renderNumber(this.props.poll.voteCount, '--') }<Diff value={pollDiff.votesDiff} /></p></Link>
                  </div>
                  <div className="email-count">
                    <label>Emails<Tooltip>All the emails you have collected with this survey.</Tooltip></label>
                    <Link to={`/emails/a/${encode(this.props.account._id)}/p/${encode(this.props.poll._id)}`}><p title={this.props.poll.emailCount || 0}>{ renderNumber(this.props.poll.emailCount, '--') }<Diff value={pollDiff.emailsDiff} /></p></Link>
                  </div>
                  <div className="participant-count">
                    <label>Participants<Tooltip>All the participants who have responded this survey.</Tooltip></label>
                    <Link to={`/participants/a/${encode(this.props.account._id)}/p/${encode(this.props.poll._id)}`}><p title={this.props.poll.participantCount || 0}>{ renderNumber(this.props.poll.participantCount, '--') }<Diff value={pollDiff.participantsDiff} /></p></Link>
                  </div>
                  { sentimentStats }
                </div>
              </div>
            </div>

            <div className="dashboard-container" style={{ minHeight: '0px' }}>
              <div style={{ margin: '0px auto', width: '100%' }}>
                <PollCard export={this.export.bind(this)} idx={this.props.idx} resetPollStats={this.props.resetPollStats} charts={this.props.charts} diffs={this.props.diffs} setIdx={this.props.setIdx} accountId={this.props.accountId} account={this.props.account} fetchPollWithSlides={this.props.fetchPollWithSlides} updateInsights={this.props.updateInsights} {...this.props.activePoll} />
              </div>
            </div>
          </div>

          <div className="preview-container-wrapper">
            <SurveyPreviewHeader
              account={this.props.account}
              poll={this.props.poll}
            />
            <div className="preview-container">
              <DashboardVisualDisplay
                pollId={this.props.poll ? this.props.poll._id : undefined}
                pageDisplaySettings={pageDisplaySettings}
                slides={this.props.poll ? this.props.poll.slides : []}
                active={true}
                pollType={getPollDisplayType(this.props.poll)}
                toggleActive={() => this.setState({ launcherActive: !this.state.launcherActive })}
                idx={this.props.idx ? this.props.idx : 0 }
                setIdx={this.props.setIdx.bind(this)}
                belowVisualDisplay={belowVisualDisplay}
                {...getDisplaySettings(this.props.account, this.props.poll)}
              />
            </div>
          </div>
        </div>

        <HeaderButtons>
          <button
            onClick={this.export.bind(this)}
            disabled={this.props.activePoll.participantCount ? false : true}
          >Export to CSV</button>
        </HeaderButtons>

        <ExportCsvModal
          show={this.state.showExportModal}
          onCancel={() => this.setState({ showExportModal: false })}
          onConfirm={({ email, responsePollId, dateRange }) => {
            this.props.exportPollParticipants(email, responsePollId, dateRange);
          }}
          pollId={this.props.pollId}
          dashboardExport={true}
          title={'Export Survey'}
          subtitle={'This will export all participants who have ever interacted with this survey.'}
          copy={'The CSV will be sent to the email listed below. It can take up to 1 hour to recieve a CSV export. The duration will depend on the amount of data your account has accumulated.'}
          email={this.props.user.email}
        />
      </ColumnLayout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const pollId = decode(ownProps.match.params.pollId);
  const slideId = decode(ownProps.match.params.slideId);

  const accounts = state.accounts || {};
  const account = accounts[accountId];

  const polls = state.polls || [];
  let poll = polls[pollId];
  if (typeof poll !== 'object') {
    poll = undefined;
  }

  const params = qs.parse(ownProps.location.search);
  let idx = parseInt(params.idx) || 0;

  return {
    account,
    accounts,
    accountId,
    pollId,
    slideId,
    poll,
    activePoll: state.polls[pollId],
    polls: state.polls,
    user: state.user,
    diffs: state.diffs,
    charts: state.charts,
    idx
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PollActions, ...DashboardActions, ...ParticipantsActions }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
