import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import DisplaySettingsForm from './DisplaySettingsForm';

import * as PollActions from '../actions/Polls';
import * as FlashNotificationActions from '../actions/FlashNotifications';

class PollDisplaySettingsForm extends DisplaySettingsForm {
  updateSettings() {
    const poll = {};
    poll._id = this.props.poll._id;
    poll.displaySettings = { ...this.state };

    const formData = new FormData();

    const launcherSettings = this.state.launcherSettings;
    const beforeSubmission = launcherSettings['before-submission'];
    if (beforeSubmission && beforeSubmission.launcherLocalImage) {
      if (beforeSubmission.launcherLocalImage === -1) {
        beforeSubmission.image = undefined;
      } else {
        formData.append('before-submission-launcherImage', beforeSubmission.launcherLocalImage);
      }
      delete poll.displaySettings.launcherSettings['before-submission'].launcherLocalImage;
    }
    const afterSubmission = launcherSettings['after-submission'];
    if (afterSubmission && afterSubmission.launcherLocalImage) {
      if (afterSubmission.launcherLocalImage === -1) {
        afterSubmission.image = undefined;
      } else {
        formData.append('after-submission-launcherImage', afterSubmission.launcherLocalImage);
      }
      delete poll.displaySettings.launcherSettings['after-submission'].launcherLocalImage;
    }

    if (this.state.localPollBackgroundImage) {
      formData.append('pollBackgroundImage', this.state.localPollBackgroundImage);
      delete poll.displaySettings.localPollBackgroundImage;
    }

    if (this.state.overlayImage) {
      formData.append('overlayImage', this.state.localOverlayImage);
      delete poll.displaySettings.localOverlayImage;
    }

    if (this.state.modalLogoImage) {
      formData.append('modalLogoImage', this.state.localModalLogoImage);
      delete poll.displaySettings.localModalLogoImage;
    }

    if (this.state.modalSideImage) {
      formData.append('modalSideImage', this.state.localModalSideImage);
      delete poll.displaySettings.localModalSideImage;
    }

    formData.append('poll', JSON.stringify(poll));

    this.props.updatePollDisplaySettings(formData, 'Your changes have been saved and pushed live.');
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PollActions, ...FlashNotificationActions }, dispatch);
}

function mapStateToProps(state) {
  return {
    loading: state.polls.loading
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PollDisplaySettingsForm);
