import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import PhoneInput from 'react-phone-number-input';
import { EmailInput } from '../components/SimpleInputs';

import _ from 'lodash';
import $ from 'jquery';

import ColumnLoading from './ColumnLoading';

import ColumnLayout from '../components/ColumnLayout';

import Breadcrumbs from '../components/Breadcrumbs';

import PollEditForm from '../components/PollEditForm';
import PollEmailForm from '../components/PollEmailForm';
import PollToggles from '../components/PollToggles';

import PollVisibilitySettingsForm from '../components/PollVisibilitySettingsForm';

import * as PollActions from '../actions/Polls';
import * as SlideActions from '../actions/Slides';
import * as RouterActions from '../actions/Router';
import * as FlashNotificationActions from '../actions/FlashNotifications';

import SingleFormPageHeader from '../components/SingleFormPageHeader';
import SectionHeader from '../components/SectionHeader';
import SurveyPreviewHeader from '../components/SurveyPreviewHeader';

import Confirm from '../components/Confirm';
import DeleteConfirm from '../components/DeleteConfirm';
import DuplicateModal from '../components/DuplicateModal';

import Toggle from '../components/Toggle';
import Tooltip from '../components/Tooltip';
import VisibilityToggle from '../components/VisibilityToggle';
import EmbedCode from '../components/EmbedCode';

import PollDisplaySettingsForm from '../components/PollDisplaySettingsForm';
import PollEmailDisplaySettingsForm from '../components/PollEmailDisplaySettingsForm';
import PollPageDisplaySettingsForm from '../components/PollPageDisplaySettingsForm';
import PollSmsDisplaySettingsForm from '../components/PollSmsDisplaySettingsForm';

import SlideBankButton from '../components/SlideBankButton';

import PollLanguageSettingsForm from '../components/PollLanguageSettingsForm';
import PollTargetingSettingsForm from '../components/PollTargetingSettingsForm';

import PollIdCard from '../components/PollIdCard';
import PublicPollLinkCard from '../components/PublicPollLinkCard';

import BrowserFrame from '../components/BrowserFrame';
import Embed from '../components/Embed';
import PagePreview from '../components/PagePreview';

import Slides from '../components/Slides';
import HeaderButtons from '../components/HeaderButtons';
import LoadingIndicator from '../components/LoadingIndicator';
import Modal from '../components/Modal';
import CSVUploader from '../components/CSVUploader';

import Papa from 'papaparse'

import AccountSettingsVisualDisplay from '../components/AccountSettingsVisualDisplay';

import { decode, encode, getTab, capitalize, previewSlides, getParams, validateEmail, generatePageDisplaySettings, getPollDisplayType, getDisplaySettings } from '../utils';

class PreviewTab extends Component {
  render() {
    let pollPreview = null;
    if (this.props.poll.slides.length) {
      pollPreview = (
        <div className="preview-embed card">
          <SectionHeader
            className="center"
            title="Want to see it in action?"
            subtitle="Click to view an interactive version of your survey."
            tooltip="This will open up a new page with a live version of your current survey."
            bottom={true}
          />

          <a target='_blank' rel="noopener noreferrer" href={`/preview/${this.props.accountId}/${this.props.pollId}`}>Preview</a>
        </div>
      );
    } else {
      pollPreview = (
        <div className="empty-object-list" style={{ marginTop: 25 }}>
          <h3>There are no slides for this survey.</h3>
          <p>Please create a slide in order to see a preview.</p>
          <Link className="green" to={`/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/create`}>Create Slide</Link>
        </div>
      )
    }

    return pollPreview;
  }
}

class ManageTab extends Component {
  constructor(props) {
    super(props)
    this.state = {};
  }

  render() {
    let disabled = this.state.csv ? false : true;
    if (this.state.importing) {
      disabled = true;
    }
    if (this.props.polls.loading) {
      disabled = true;
    }
    let pageDisplaySettings = this.props.account.pageDisplaySettings || generatePageDisplaySettings(this.props);
    if (this.props.poll && this.props.poll.pageDisplaySettings) {
      pageDisplaySettings = this.props.poll.pageDisplaySettings;
    }

    const slides = this.props.poll ? this.props.poll.slides : [];
    return (
      <div className="split big account-display">
        <div>
          <SectionHeader
            title=<span><i class="fas fa-tasks" />Management Options</span>
            subtitle="Control your account title and domain settings."
            className="no-margin small"
          />

          <div className="poll-management card account-settings" style={{ marginBottom: 20 }}>
            <div style={{marginBottom: 25}}>
              <label>Duplicate Survey<Tooltip>This will copy the survey exactly. No results or interactions will be ported over to the duplicate.</Tooltip></label>

              <div>
                <div className="subtle">This will make a copy the survey and its slides.</div>
                <button
                  className="duplicate-poll"
                  onClick={(value) => {
                    this.setState({ showDuplicateModal: true });
                  }}
                >Duplicate</button>
              </div>
            </div>

            <div style={{marginBottom: 25}}>
              <label>Archive Survey<Tooltip>This will archive the survey so that it is only visible on the dashboard. You can unarchive at any time.</Tooltip></label>
              <div className="inline-toggle">
                <Toggle
                  active={this.props.poll.isArchived}
                  onChange={(value) => {
                    if (this.props.poll.isArchived) {
                      this.props.unarchivePoll();
                    } else {
                      this.setState({ showArchiveConfirm: true });
                    }
                  }}
                />
                <div className="subtle">Hide everywhere except your dashboard.</div>
              </div>
            </div>

            <div>
              <label>Delete Survey</label>
              <div>
                <div className="subtle">This will erase the survey, its slides, and responses.</div>
                <button
                  className="delete-poll"
                  onClick={(value) => {
                    this.setState({ showDeleteConfirm: true });
                  }}
                >Delete</button>
              </div>
            </div>
          </div>

          <PollIdCard _id={this.props.poll._id} />

          <div style={{ marginBottom: 20 }}></div>

          <PublicPollLinkCard
            account={this.props.account}
            poll={this.props.poll}
            pollId={this.props.pollId}
            accountId={this.props.accountId}
            integrationSettings={this.props.account.integrationSettings || {}}
            toggleVisibility={this.props.toggleVisibility}
            className="no-margin small"
          />

          <SectionHeader
            title=<span><i class="fas fa-file-import" />Import Data</span>
            subtitle="Migrate existing data into this survey using a CSV import."
            className="small no-margin"
          />

          <div className="poll-management card full-width">
            <label>Upload data csv<Tooltip><a href="https://docs.zigpoll.com/polls/import" target="_blank">Click here</a> to learn more about our CSV structure and to download an example template.</Tooltip></label>
            <CSVUploader
              file={this.state.file}
              onChange={(file) => {
                if (!file) {
                  const state = { ...this.state };
                  state.file = undefined;
                  state.csv = undefined;
                  this.setState(state);
                  return;
                }

                Papa.parse(file, {
                  skipEmptyLines: true,
                  error: (err, file, inputElem, reason) => {
                    console.log(err);
                  },
                  complete: ({ data }) => {
                    const state = { ...this.state };
                    state.csv = data;
                    state.file = file;
                    console.log(this.state);
                    this.setState(state);
                  }
                });
              }}
            />

            <button
              className={`duplicate-poll ${this.state.csv ? 'active' : 'disabled'}`}
              disabled={disabled}
              onClick={(value) => {
                this.setState({ importing: true });
                this.props.runCsvImport(this.state.csv).then(() => {
                  this.setState({ showImportSuccessModal: true });
                });
              }}
            >{ this.state.importing ? 'Uploading...' : 'Upload' }</button>
          </div>

          <SectionHeader
            title=<span><i class="fas fa-file-export" />Export Survey</span>
            className="small no-margin"
            subtitle="Export your survey questions & settings to a file."
            tooltip="Export your survey questions & settings to a .json file so you can import them easily into a different account."
          />
          <div className="poll-management full-width card">
            <p class="subtle" style={{ fontSize: 18, marginBottom: 15, lineHeight: 1.25 }}>Click below to generate an export file for this survey. You can use this file to easily import the survey into a new account.</p>
            <button
              className={`duplicate-poll ${this.state.exporting ? 'active' : 'disabled'}`}
              disabled={this.state.exporting}
              onClick={(value) => {
                this.setState({ exporting: true });
                this.props.generateExportFile()
                .then(() => {
                  this.setState({ exporting: false });
                })
              }}
            >{ this.state.exporting ? 'Exporting...' : 'Generate Export File' }</button>
          </div>
        </div>
        <div className="preview-container-wrapper">
          <SurveyPreviewHeader
            account={this.props.account}
            poll={this.props.poll}
          />
          <div className="preview-container">
            <AccountSettingsVisualDisplay
              pageDisplaySettings={pageDisplaySettings}
              slides={slides}
              active={true}
              pollType={getPollDisplayType(this.props.poll)}
              toggleActive={() => this.setState({ launcherActive: !this.state.launcherActive })}
              belowVisualDisplay={this.props.belowVisualDisplay}
              scalePoll={false}
              showSlideWarning={true}
              {...getDisplaySettings(this.props.account, this.props.poll)}
            />
          </div>
        </div>
        {/*
        <EmbedCode account={this.props.account} accounts={this.props.accounts} user={this.props.user} />
        */}

        <DuplicateModal 
          title="Duplicate Survey"
          subtitle="What should the copy be called?"
          show={this.state.showDuplicateModal}
          onConfirm={(value) => this.props.duplicatePoll(value)}
          value={`${this.props.poll.title}`}
          onCancel={() => {
            this.setState({ showDuplicateModal: false });
          }}
        />

        <DeleteConfirm 
          title="Are you sure?"
          subtitle="This will permanently erase the survey, its slides, and responses forever."
          show={this.state.showDeleteConfirm}
          onConfirm={this.props.deletePoll}
          onCancel={() => {
            this.setState({ showDeleteConfirm: false });
          }}
        />

        <Modal 
          isOpen={this.state.showImportSuccessModal}
          onRequestClose={() => { this.setState({ showImportSuccessModal: false, csv: undefined, file: undefined, importing: true }) }}
        >
          <div className="frame">
            <div className="close" onClick={() => { this.setState({ showImportSuccessModal: false, csv: undefined, file: undefined, importing: true }) }} />
            <div className="title">Upload Successful</div>
            <div className="content">
              <div className="subtitle" style={{ marginBottom: 10 }}>Your import has started running.</div>
              <p className="copy">You will receive an email when it has been completed. You can close this window and navigate to another page.</p>
              <div className="actions">
                <button className="positive" onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.setState({ showImportSuccessModal: false, csv: undefined, file: undefined, importing: true })
                }}>Close</button>
              </div>
            </div>
          </div>
        </Modal>

        <Confirm 
          title="Are you sure?"
          subtitle="This will set the survey's design settings to hidden and make it only accessible from the dashboard."
          show={this.state.showArchiveConfirm}
          onConfirm={this.props.archivePoll}
          onCancel={() => {
            this.setState({ showArchiveConfirm: false });
          }}
        />
      </div>
    );
  }
}

class VisualDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = { active: true };
    this.scroll = _.throttle(this.scroll.bind(this), 0, { leading: true, trailing: true });
  }

  componentDidMount() {
    $('.foreground').on('scroll.slides', this.scroll);
    this.scroll();
  }

  componentWillUnmount() {
    $('.foreground').off('scroll.slides', this.scroll)
  }

  scroll() {
    // let $el = $('.visual-display-wrapper').first();

    // let zoom = $('html').css('zoom') || 1;
    // zoom = parseFloat(zoom);

    // let t = $('.slide-form-wrapper')[0].getBoundingClientRect().y - document.documentElement.getBoundingClientRect().y * zoom;
    // let st = 0;

    // let b = $('.slide-form-wrapper .slide-form').height();
    // if (b < 600) { b = 600; }
    // let atBottom = ((st - t) + 680 + 80) >= b;

    // if ( (t) < 80 && !atBottom ) {
    //   $el.css('transform', `translateY(${-t + 80}px)`);
    // } else if (atBottom) {
    //   $el.css('transform', `translateY(${b - 680}px)`);
    // } else {
    //   $el.css('transform', `translateY(0px)`);
    // }
  }

  render() {
    const slides = this.props.slides || [];
    let hasBranchingSlides = false;

    slides.forEach((slide) => {
      if (slide.nextSlide) {
        hasBranchingSlides = true;
      }

      if (slide.answers && previewSlides[slide.type].answers) {
        slide.answers.forEach(({ nextSlide }) => {
          if (nextSlide) {
            hasBranchingSlides = true;
          }
        })
      }
    })

    let previewType = <Embed 
      { ...this.props }
      toggleActive={() => { this.setState({ active: !this.state.active})}}
      active={this.state.active}
      next={this.props.next}
      prev={this.props.prev}
      goto={this.props.goto}
      currentIdx={this.props.currentIdx}
      isEmbed={this.props.pollType === 'embed'}
    />

    if (['widget', 'embed', 'api'].indexOf(this.props.pollType) === -1) {
      previewType = <PagePreview
        {...this.props}
      />
    }

    const belowVisualDisplay = <div className="below-visual-display"><i className="fas fa-arrow-turn-up" />A preview of this survey is printed in the box above. Click the title or edit icon for any slide in order to make changes.</div>

    return (
      <div className="visual-display-wrapper">
        <div className="visual-display">
          <BrowserFrame 
            title="Survey Preview"
            subtitle="Flip through your survey below."
            info={hasBranchingSlides ? 'This survey has branching slides. You may not see all these slides when filling out the survey.' : null}
          />
          { previewType }
        </div>
        { belowVisualDisplay }
      </div>      
    );
  }
}

class LanguageTab extends Component {
  constructor(props) {
    super(props);
    this.state = { showLanguageSettings: undefined };
  }

  render() {
    let showLanguageSettings = this.state.showLanguageSettings;
    if (showLanguageSettings === undefined) {
      showLanguageSettings = this.props.poll.languageSettings;
    }

    // let pollLanguageSettings = null;
    // if (showLanguageSettings) {
    const pollLanguageSettings = (<PollLanguageSettingsForm
        account={this.props.account}
        poll={this.props.poll}
        hideTitle={true}
        pollId={this.props.poll._id}
        // style={{ marginTop: 25 }}
      />);
    // }

    let style = {};
    const content = (<div>
      <div style={style}>
        <SectionHeader
          title=<span><i class="fas fa-globe-americas"/>Language Settings</span>
          subtitle="Control your survey's translation settings."
          className="no-margin"
        />

        <div className="poll-management card">
          <div style={{marginBottom: 0}}>
            <label>Override Language Settings<Tooltip>By default all surveys use the Account's language settings. Trigger this if you want to override the language settings for this survey only.</Tooltip></label>
            <div className="inline-toggle">
              <Toggle
                active={showLanguageSettings}
                onChange={(value) => {
                  if (showLanguageSettings) {
                    if (this.props.poll.languageSettings) {
                      this.setState({ showDisplayConfirm: true });
                    } else {
                      this.setState({ showLanguageSettings: false });
                    }
                  } else {
                    this.setState({ showLanguageSettings: true });
                  }
                }}
              />
              <div className="subtle">Use custom language settings for this survey only.</div>
            </div>
          </div>
        </div>
      </div>

      { pollLanguageSettings }

      <div className="card info center" style={{ marginTop: 20 }}>
        <div className="top">Want to translate all surveys?</div>
        <p>Go to <Link to={`/a/${encode(this.props.accountId)}?tab=language`}>account language settings</Link>.</p>
      </div>

      <Confirm 
        title="Are you sure?"
        subtitle="This will remove the survey's custom design settings."
        show={this.state.showDisplayConfirm}
        onConfirm={() => {
          const poll = {};
          poll._id = this.props.poll._id;
          poll.languageSettings = false;
          this.props.updatePoll(poll, 'Your changes have been saved and pushed live.');
          this.setState({ showLanguageSettings: false });
        }}
        onCancel={() => {
          this.setState({ showDisplayConfirm: false });
        }}
      />
    </div>);

    return pollLanguageSettings;
  }
}

class SlidesTab extends Component {
  constructor(props) {
    super(props);
    this.state = { currentIdx: 0, prevIndexes: [] };
  }

  next() {
    this.state.prevIndexes.push(this.state.currentIdx);
    this.setState({ currentIdx: this.state.currentIdx + 1 });
  }

  prev() {
    const idx = this.state.prevIndexes.pop();
    if (idx !== undefined) {
      this.setState({ currentIdx: idx });
    }
  }

  goto(slideId) {
    let idx = undefined;
    this.props.poll.slides.forEach(({ _id }, i) => {
      if (_id === slideId) {
        idx = i;
      }
    })
    if (idx === undefined) { return this.next(); }

    this.state.prevIndexes.push(this.state.currentIdx);
    this.setState({ currentIdx: idx });
  }

  reset() {
    this.setState({ currentIdx: 0, prevIndexes: [] });
  }

  closeModal() {
    this.setState({ showQuickDeleteModal: false });
    this.onConfirm = undefined;
  }

  render() {
    let content;
    let buttons;

    let settings = this.props.account.displaySettings;
    if (this.props.poll.displaySettings) {
      settings = this.props.poll.displaySettings;
    }

    let slidesDisplay = (<div className="empty-object-list" style={{ marginTop: 25 }}>
        <h3>There are no slides for this survey.</h3>
        <p>Slides are the building blocks of a survey. They can do a variety of things from asking multi-choice questions to collecting customer information.</p>
        <p>Get started by clicking the link below.</p>
        <Link className="green" to={`/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/create`}>Create Slide</Link>
      </div>);

    if (this.props.poll.slides.length) {
      let showCounter = false;
      if (this.props.poll.settings && this.props.poll.settings.showCounter) {
        showCounter = true;
      }

      let pollType = 'widget';
      if (this.props.poll.settings && this.props.poll.settings.selector) {
        pollType = 'embed';
      }
      if (this.props.poll.settings && this.props.poll.settings.apiOnly) {
        pollType = 'link';
      }
      if (this.props.poll.settings && this.props.poll.settings.shopifyAbandonedCheckout) {
        pollType = 'abandoned-checkout';
      }
      if (this.props.poll.settings && this.props.poll.settings.shopifyAbandonedCheckout) {
        pollType = 'email';
      }
      if (this.props.poll.settings && this.props.poll.settings.shopifyWebhooks) {
        pollType = 'email';
      }
      if (this.props.poll.settings && this.props.poll.settings.sms) {
        pollType = 'sms';
      }
      if (this.props.poll.settings && this.props.poll.settings.pollType === 'api') {
        pollType = 'api';
      }

      let pageDisplaySettings = this.props.account.pageDisplaySettings || generatePageDisplaySettings(this.props);
      if (this.props.poll.pageDisplaySettings) {
        pageDisplaySettings = this.props.poll.pageDisplaySettings;
      }

      slidesDisplay = (
        <div className="split big">
          <div>
            <SectionHeader
              className="center no-margin small"
              title=<span><i className="fas fa-poll-h" />Slide Management</span>
              subtitle="Drag and drop to adjust the order."
              tooltip="Each slide is a step of your survey. There are several types of slides that can be used to capture customer feedback. You can add as many as you want."
              style={{ textAlign: 'left' }}
              bottom={true}
            />

            <Slides
              {...this.props}
              goto={this.goto.bind(this)}
              reset={this.reset.bind(this)}
              currentIdx={this.state.currentIdx}
              account={this.props.account}
              delete={(_id) => {
                console.log('deleting at index');
                // this.props.removeAtIndex(idx);
                this.setState({ showQuickDeleteModal: true });
                this.onConfirm = () => {
                  this.props.removeById(_id);
                  this.reset();
                  this.onConfirm = undefined;
                }
              }}
              update={(_id) => {
                console.log('updating at index');
              }}
              duplicate={this.props.duplicateFromIdx.bind(this)}
            />
          </div>
          <div className="preview-container-wrapper">
            <SurveyPreviewHeader
              account={this.props.account}
              poll={this.props.poll}
            />
            <div className="preview-container">
              <VisualDisplay
                slides={ [...this.props.poll.slides] }
                currentIdx={this.state.currentIdx}
                prev={this.prev.bind(this)}
                next={this.next.bind(this)}
                goto={this.goto.bind(this)}
                singleSlidePage={true}
                showCounter={showCounter}
                pageDisplaySettings={pageDisplaySettings}
                pollType={pollType}
                hideCloseButton={this.props.poll.settings.hideCloseButton}
                hideXButton={this.props.poll.settings.hideXButton}
                {...settings }
              />
            </div>
          </div>
          <Modal 
            isOpen={this.state.showQuickDeleteModal}
            onRequestClose={this.closeModal.bind(this)}
          >
            <div className="frame">
              <div className="close" onClick={this.closeModal.bind(this)} />
              <div className="title">Are you sure?</div>
              <div className="content">
                <div className="subtitle">This will remove the slide and all responses to it.</div>
                <div className="actions">
                  <button className="positive" onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    this.onConfirm();
                    this.closeModal();
                  }}>Yes</button>
                  <button className="negative" onClick={this.closeModal.bind(this)}>No</button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      );
    }

    content = (
      <div style={{ minWidth: 1120 }}>
        <div className="slide-reorder-display">
          { slidesDisplay }
        </div>

        <HeaderButtons>
          <Link to={`/a/${encode(this.props.accountId)}/p/${encode(this.props.pollId)}/s/create`} className="create">Add Slide</Link>
          <SlideBankButton skipRedirect={true} />
        </HeaderButtons>

      </div>
    );

    return content;
  }
}

function getSwitchState(tab) {
  if (tab === 'widget') {
    return 'one';
  }
  if (tab === 'page') {
    return 'two';
  }
  if (tab === 'email') {
    return 'three';
  }
  if (tab === 'sms') {
    return 'four';
  }
}

class DisplayTab extends Component {
  constructor(props) {
    super(props);
    const tab = props.params.type || 'widget';
    this.props.setParam('type', tab);
    this.state = { tab };

    this.state = { showDisplaySettings: undefined, tab };
  }

  onClick(tab, e) {
    this.props.setParam('type', tab);
    this.setState({ tab });
  }

  renderWidgetForm(tabs) {
    // let showDisplaySettings = this.state.showDisplaySettings;
    let showDisplaySettings = true;
    if (showDisplaySettings === undefined) {
      showDisplaySettings = this.props.poll.displaySettings;
    }

    let pollDisplaySettings = null;
    if (showDisplaySettings) {
      pollDisplaySettings = (<PollDisplaySettingsForm
        account={this.props.account}
        settings={this.props.poll.displaySettings || this.props.account.displaySettings}
        tabs={tabs}
        poll={this.props.poll}
        belowVisualDisplay={<div className="below-visual-display">
          <i className="fas fa-arrow-turn-up" />This is an <strong>on-site</strong> preview of your survey titled: <strong>{ this.props.poll.title }</strong>. to edit the default appearance for all your surveys go to <Link to={`/a/${encode(this.props.accountId)}?tab=design&type=widget`}>account design settings</Link>.
        </div>}
        // style={{ marginTop: 25 }}
      />);
    }

    let style = {};

    if (showDisplaySettings) {
      style = { minWidth: 1100 };
    }

    return (
    <div>
      {/*
      <div style={style}>

        <SectionHeader
          title=<span><i className="fas fa-palette" />Widget Design Settings</span>
          subtitle="Control the appearance of your launcher, survey, and slides."
          className="no-margin center"
        />

        <div className="poll-management card">
          <div style={{marginBottom: 0}}>
            <label>Override Design Settings<Tooltip>By default all surveys use the Account design settings. Trigger if you want to override these settings for this survey.</Tooltip></label>
            <div className="inline-toggle">
              <Toggle
                active={showDisplaySettings}
                onChange={(value) => {
                  if (showDisplaySettings) {
                    if (this.props.poll.displaySettings) {
                      this.setState({ showDisplayConfirm: true });
                    } else {
                      this.setState({ showDisplaySettings: false });
                    }
                  } else {
                    this.setState({ showDisplaySettings: true });
                  }
                }}
              />
              <div className="subtle">Use custom design settings for this survey only.</div>
            </div>
          </div>
        </div>
      </div>
      */}

      { pollDisplaySettings }

      <Confirm 
        title="Are you sure?"
        subtitle="This will remove the survey's custom design settings."
        show={this.state.showDisplayConfirm}
        onConfirm={() => {
          const poll = {};
          poll._id = this.props.poll._id;
          poll.displaySettings = false;
          this.props.updatePoll(poll, 'Your changes have been saved and pushed live.');
          this.setState({ showDisplaySettings: false });
        }}
        onCancel={() => {
          this.setState({ showDisplayConfirm: false });
        }}
      />
    </div>
    );
  }

  renderEmailForm(tabs) {
   let showEmailDisplaySettings = true;
   // let showEmailDisplaySettings = this.state.showEmailDisplaySettings;

    if (showEmailDisplaySettings === undefined) {
      showEmailDisplaySettings = this.props.poll.emailDisplaySettings;
    }

    let pollEmailDisplaySettings = null;
    if (showEmailDisplaySettings) {
      pollEmailDisplaySettings = (<PollEmailDisplaySettingsForm
        account={this.props.account}
        settings={this.props.poll.emailDisplaySettings || this.props.account.emailDisplaySettings}
        poll={this.props.poll}
        tabs={tabs}
        belowVisualDisplay={<div className="below-visual-display">
          <i className="fas fa-arrow-turn-up" />This is an <strong>email</strong> preview of your survey titled: <strong>{ this.props.poll.title }</strong>. to edit the default appearance for all your surveys go to <Link to={`/a/${encode(this.props.accountId)}?tab=design&type=email`}>account design settings</Link>.<br/><br/>Want to test out your email template? <u style={{ cursor: 'pointer' }} onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          this.setState({ showTestEmailModal: true });
        }}>Send yourself a test</u>.</div>}
        // style={{ marginTop: 25, minWidth: 1100 }}
      />);
    }

    let style = {};

    if (showEmailDisplaySettings) {
      style = { minWidth: 1100 };
    }

    return (
    <div>
      {/*
      <div style={style}>
        <SectionHeader
          title=<span><i className="fas fa-mail-bulk"/>Email Design Settings</span>
          subtitle="Control the appearance of your email campaigns."
          className="no-margin center"
        />

        <div className="poll-management card">
          <div style={{marginBottom: 0}}>
            <label>Override Design Settings<Tooltip>By default all surveys use the Account design settings. Trigger if you want to override these settings for this survey.</Tooltip></label>
            <div className="inline-toggle">
              <Toggle
                active={showEmailDisplaySettings}
                onChange={(value) => {
                  if (showEmailDisplaySettings) {
                    if (this.props.poll.emailDisplaySettings) {
                      this.setState({ showEmailDisplayConfirm: true });
                    } else {
                      this.setState({ showEmailDisplaySettings: false });
                    }
                  } else {
                    this.setState({ showEmailDisplaySettings: true });
                  }
                }}
              />
              <div className="subtle">Use custom design settings for this survey only.</div>
            </div>
          </div>
        </div>
      </div>
      */}

      { pollEmailDisplaySettings }

      {/*
      <div className="card info wide center" style={{ marginTop: 20 }}>
        <div className="top">Want to edit the default appearance for all surveys?<
        <p>Go to <Link to={`/a/${encode(this.props.accountId)}?tab=design&type=email`}>account design settings</Link>.</p>
        <p>Want to test out your email template? <u style={{ cursor: 'pointer' }} onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          this.setState({ showTestEmailModal: true });
        }}>Send yourself a test</u>.</p>
      </div>
      */}

      <Modal 
        isOpen={this.state.showTestEmailModal}
        onRequestClose={() => this.closeEmailTestModal()}
      >
        <div className="frame">
          <div className="close" onClick={() => this.closeEmailTestModal()} />
          <div className="title">Enter your email address</div>
          <div className="content">
            <div className="phone-input">
              <EmailInput
                placeholder={"testing@mydomain.com"}
                value={this.state.email}
                noValidate={true}
                onChange={(e) => {
                  const email = e.target.value;
                  this.setState({ email });
                }}
                defaultCountry={'US'}
              />
            </div>
            <div className="actions">
              <button className="positive" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                const email = this.state.email;

                if (!validateEmail(email)) {
                  return this.props.flash('Please enter a valid email address');
                }

                this.props.runEmailCampaign([email]);
                this.closeEmailTestModal()
              }}>Send Email</button>
              <button className="negative" onClick={() => this.closeSmsTestModal()}>Cancel</button>
            </div>
          </div>
        </div>
      </Modal>

      <Confirm 
        title="Are you sure?"
        subtitle="This will remove the survey's custom email design settings."
        show={this.state.showEmailDisplayConfirm}
        onConfirm={() => {
          const poll = {};
          poll._id = this.props.poll._id;
          poll.emailDisplaySettings = false;
          this.props.updatePoll(poll, 'Your changes have been saved and pushed live.');
          this.setState({ showEmailDisplaySettings: false });
        }}
        onCancel={() => {
          this.setState({ showEmailDisplayConfirm: false });
        }}
      />
    </div>
    );
  }

  renderPageForm(tabs) {
   let showPageDisplaySettings = true;
   // let showPageDisplaySettings = this.state.showPageDisplaySettings;
    if (showPageDisplaySettings === undefined) {
      showPageDisplaySettings = this.props.poll.pageDisplaySettings;
    }

    let pollPageDisplaySettings = null;
    if (showPageDisplaySettings) {
      pollPageDisplaySettings = (<PollPageDisplaySettingsForm
        account={this.props.account}
        settings={this.props.poll.pageDisplaySettings || this.props.account.pageDisplaySettings}
        poll={this.props.poll}
        tabs={tabs}
        // style={{ marginTop: 25, minWidth: 1100 }}
        belowVisualDisplay={<div className="below-visual-display">
          <i className="fas fa-arrow-turn-up" />This is a <strong>page</strong> preview of your survey titled: <strong>{ this.props.poll.title }</strong>. to edit the default appearance for all your surveys go to <Link to={`/a/${encode(this.props.accountId)}?tab=design&type=page`}>account design settings</Link>.
        </div>}
      />);
    }

    let style = {};

    if (showPageDisplaySettings) {
      style = { minWidth: 1100 };
    }

    return (
    <div>
      {/*
      <div style={style}>
        <SectionHeader
          title=<span><i className="fas fa-link"/>Page Design Settings</span>
          subtitle="Control the appearance of your share page."
          className="no-margin center"
        />

        <div className="poll-management card">
          <div style={{marginBottom: 0}}>
            <label>Override Design Settings<Tooltip>By default all surveys use the Account design settings. Trigger if you want to override these settings for this survey.</Tooltip></label>
            <div className="inline-toggle">
              <Toggle
                active={showPageDisplaySettings}
                onChange={(value) => {
                  if (showPageDisplaySettings) {
                    if (this.props.poll.pageDisplaySettings) {
                      this.setState({ showPageDisplayConfirm: true });
                    } else {
                      this.setState({ showPageDisplaySettings: false });
                    }
                  } else {
                    this.setState({ showPageDisplaySettings: true });
                  }
                }}
              />
              <div className="subtle">Use custom design settings for this survey only.</div>
            </div>
          </div>
        </div>
      </div>
      */}

      { pollPageDisplaySettings }

      {/*
      <div className="card info wide center" style={{ marginTop: 20 }}>
        <div className="top">Want to edit the default appearance for all surveys?<
        <p>Go to <Link to={`/a/${encode(this.props.accountId)}?tab=design&type=page`}>account design settings</Link>.</p>
      </div>
      */}

      <Confirm 
        title="Are you sure?"
        subtitle="This will remove the survey's custom email design settings."
        show={this.state.showPageDisplayConfirm}
        onConfirm={() => {
          const poll = {};
          poll._id = this.props.poll._id;
          poll.pageDisplaySettings = false;
          this.props.updatePoll(poll, 'Your changes have been saved and pushed live.');
          this.setState({ showPageDisplaySettings: false });
        }}
        onCancel={() => {
          this.setState({ showPageDisplayConfirm: false });
        }}
      />
    </div>
    );
  }


  closeSmsTestModal() {
    this.setState({ 
      showTestSmsModal: false,
      phoneNumber: undefined 
    })
  }

  closeEmailTestModal() {
    this.setState({ 
      showTestEmailModal: false,
      email: undefined 
    })
  }

  renderSmsForm(tabs) {
   let showSmsDisplaySettings = true;
   // let showSmsDisplaySettings = this.state.showSmsDisplaySettings;
    if (showSmsDisplaySettings === undefined) {
      showSmsDisplaySettings = this.props.poll.smsDisplaySettings;
    }

    let pollSmsDisplaySettings = null;
    if (showSmsDisplaySettings) {
      pollSmsDisplaySettings = (<PollSmsDisplaySettingsForm
        account={this.props.account}
        settings={this.props.poll.smsDisplaySettings || this.props.account.smsDisplaySettings}
        poll={this.props.poll}
        tabs={tabs}
        belowVisualDisplay={<div className="below-visual-display">
          <i className="fas fa-arrow-turn-up" />This is a <strong>SMS</strong> preview of your survey titled: <strong>{ this.props.poll.title }</strong>. to edit the default appearance for all your surveys go to <Link to={`/a/${encode(this.props.accountId)}?tab=design&type=sms`}>account design settings</Link>.<br/><br/>Want to test out your sms will look? <u style={{ cursor: 'pointer' }} onClick={() => {
          // this.props.sendSmsTest();
          this.setState({ showTestSmsModal: true });
        }}>Send yourself a test</u>.</div>}
        // style={{ marginTop: 25, minWidth: 1100 }}
      />);
    }

    let style = {};

    if (showSmsDisplaySettings) {
      style = { minWidth: 1100 };
    }

    return (
    <div>
      {/*
      <div style={style}>
        <SectionHeader
          title=<span><i className="fas fa-sms"/>Sms Design Settings</span>
          subtitle="Control the appearance of your sms campaigns."
          className="no-margin center"
        />

        <div className="poll-management card">
          <div style={{marginBottom: 0}}>
            <label>Override Design Settings<Tooltip>By default all surveys use the Account design settings. Trigger if you want to override these settings for this survey.</Tooltip></label>
            <div className="inline-toggle">
              <Toggle
                active={showSmsDisplaySettings}
                onChange={(value) => {
                  if (showSmsDisplaySettings) {
                    if (this.props.poll.smsDisplaySettings) {
                      this.setState({ showSmsDisplayConfirm: true });
                    } else {
                      this.setState({ showSmsDisplaySettings: false });
                    }
                  } else {
                    this.setState({ showSmsDisplaySettings: true });
                  }
                }}
              />
              <div className="subtle">Use custom design settings for this survey only.</div>
            </div>
          </div>
        </div>
      </div>
      */}

      { pollSmsDisplaySettings }

      {/*
      <div className="card info wide center" style={{ marginTop: 20 }}>
        <div className="top">Want to edit the defaults for all surveys?<
        <p>Go to <Link to={`/a/${encode(this.props.accountId)}?tab=design&type=sms`}>account design settings</Link>.</p>
        <p>Want to test out your sms will look? <u style={{ cursor: 'pointer' }} onClick={() => {
          // this.props.sendSmsTest();
          this.setState({ showTestSmsModal: true });
        }}>Send yourself a test</u>.</p>
      </div>
      */}

      <Modal 
        isOpen={this.state.showTestSmsModal}
        onRequestClose={() => this.closeSmsTestModal()}
      >
        <div className="frame">
          <div className="close" onClick={() => this.closeSmsTestModal()} />
          <div className="title">Enter your phone number</div>
          <div className="content">
            <div className="phone-input">
              <PhoneInput
                flagUrl='https://cdn.zigpoll.com/country-flag-icons/3x2/{XX}.svg'
                placeholder={"Enter phone number"}
                value={this.state.phoneNumber}
                key={this.state.key || 'US'}
                onChange={(value) => {
                  this.setState({ phoneNumber: value });
                }}
                defaultCountry={'US'}
              />
            </div>
            <div className="actions">
              <button className="positive" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                if (!this.state.phoneNumber) {
                  return this.props.flash('Please enter a phone number');
                }

                this.props.sendSmsTest(this.state.phoneNumber);
                this.closeSmsTestModal()
              }}>Send SMS</button>
              <button className="negative" onClick={() => this.closeSmsTestModal()}>Cancel</button>
            </div>
          </div>
        </div>
      </Modal>

      <Confirm 
        title="Are you sure?"
        subtitle="This will remove the survey's custom sms design settings."
        show={this.state.showSmsDisplayConfirm}
        onConfirm={() => {
          const poll = {};
          poll._id = this.props.poll._id;
          poll.smsDisplaySettings = false;
          this.props.updatePoll(poll, 'Your changes have been saved and pushed live.');
          this.setState({ showSmsDisplaySettings: false });
        }}
        onCancel={() => {
          this.setState({ showSmsDisplayConfirm: false });
        }}
      />
    </div>
    );

  }

  render() {
    let formContent = null;
    let tabs = <div className={`big-switch squared quad ${getSwitchState(this.state.tab)}`}>
      <div className={`${this.state.tab === 'widget' ? 'active' : ''}`} onClick={this.onClick.bind(this, 'widget')}><i className="fas fa-poll" />On-Site</div>
      <div className={`${this.state.tab === 'page' ? 'active' : ''}`} onClick={this.onClick.bind(this, 'page')}><i className="fas fa-link" />Page</div>
      <div className={`${this.state.tab === 'email' ? 'active' : ''}`} onClick={this.onClick.bind(this, 'email')}><i className="fas fa-envelope" />Email</div>
      <div className={`${this.state.tab === 'sms' ? 'active' : ''}`} onClick={this.onClick.bind(this, 'sms')}><i className="fas fa-sms" />SMS</div>
    </div>

    if (this.state.tab === 'widget') {
      formContent = this.renderWidgetForm(tabs);
    }
    if (this.state.tab === 'email') {
      formContent = this.renderEmailForm(tabs);
    }
    if (this.state.tab === 'sms') {
      formContent = this.renderSmsForm(tabs);
    }
    if (this.state.tab === 'page') {
      formContent = this.renderPageForm(tabs);
    }

    const content = (<div>
      { formContent }
    </div>);

    return content;
  }
}

class VisibilityTab extends Component {
  constructor(props) {
    super(props);
    this.state = { showVisibilitySettings: undefined };
  }

  render() {
    // let showVisibilitySettings = this.state.showVisibilitySettings;
    let showVisibilitySettings = true;

    if (showVisibilitySettings === undefined) {
      showVisibilitySettings = this.props.poll.visibilitySettings;
    }

    let pollVisibilitySettings = null;
    if (showVisibilitySettings) {
      pollVisibilitySettings = (<PollVisibilitySettingsForm
        poll={this.props.poll}
        account={this.props.account}
        belowVisualDisplay={<div className="below-visual-display"><i className="fas fa-arrow-turn-up" />This is an <strong>on-site</strong> preview of your survey titled: <strong>{ this.props.poll.title }</strong>. to edit the default behavior settings for all your surveys go to <Link to={`/a/${encode(this.props.accountId)}?tab=design&type=behavior`}>account behavior settings</Link>.
        </div>}
      />);
    }

    return (
      <div>
        {/*
        <div className="poll-management card" style={{ marginBottom: showVisibilitySettings ? 25 : 0 }}>
          <div>
            <label>Override Visibility Settings<Tooltip>By default all surveys use the Account visibility settings. Trigger if you want to override these settings for this survey.</Tooltip></label>
            <div className="inline-toggle">
              <Toggle
                active={showVisibilitySettings}
                onChange={(value) => {
                  if (showVisibilitySettings) {
                    if (this.props.poll.visibilitySettings) {
                      this.setState({ showConfirm: true });
                    } else {
                      this.setState({ showVisibilitySettings: false });
                    }
                  } else {
                    this.setState({ showVisibilitySettings: true });
                  }
                }}
              />
              <div className="subtle">Use custom visibility settings for this poll only.</div>
            </div>
          </div>
        </div>
        */}

        { pollVisibilitySettings }

        <Confirm 
          title="Are you sure?"
          subtitle="This will remove the survey's custom behavior settings."
          show={this.state.showConfirm}
          onConfirm={() => {
            const poll = {};
            poll._id = this.props.poll._id;
            poll.visibilitySettings = false;
            this.props.updatePoll(poll, 'Your changes have been saved and pushed live.');
            this.setState({ showVisibilitySettings: false });
          }}
          onCancel={() => {
            this.setState({ showConfirm: false });
          }}
        />

      </div>
    );
  }
}

class TargetingTab extends Component {
  constructor(props) {
    super(props);
    this.state = { showTargetingSettings: this.props.poll.targetingSettings };
  }

  render() {
    // const showTargetingSettings = this.state.showTargetingSettings;
    const showTargetingSettings = true;
    let pollTargetingSettings = null;

    if (showTargetingSettings) {
      pollTargetingSettings = (<PollTargetingSettingsForm
        poll={this.props.poll}
        account={this.props.account}
        belowVisualDisplay={<div className="below-visual-display"><i className="fas fa-arrow-turn-up" />This is an <strong>on-site</strong> preview of your survey titled: <strong>{ this.props.poll.title }</strong>. to edit the default targeting settings for all your surveys go to <Link to={`/a/${encode(this.props.accountId)}?tab=design&type=targeting`}>account targeting settings</Link>.
        </div>}
      />);
    }

    return (
      <div>
        {/*
        <div className="poll-management card" style={{ marginBottom: showTargetingSettings ? 25 : 0 }}>
          <div>
            <label>Override Targeting Settings<Tooltip>By default all surveys use the Account targeting settings. Trigger if you want to override these settings for this survey.</Tooltip></label>
            <div className="inline-toggle">
              <Toggle
                active={showTargetingSettings}
                onChange={(value) => {
                  if (showTargetingSettings) {
                    if (this.props.poll.targetingSettings) {
                      this.setState({ showConfirm: true });
                    } else {
                      this.setState({ showTargetingSettings: false });
                    }
                  } else {
                    this.setState({ showTargetingSettings: true });
                  }
                }}
              />
              <div className="subtle">Use custom targeting settings for this survey only.</div>
            </div>
          </div>
        </div>
        */}

        { pollTargetingSettings }

        {/*
        <div className="card info wide center" style={{ marginTop: 20 }}>
          <div className="top">Want to set targeting rules for every survey?</div>
          <p>Go to <Link to={`/a/${encode(this.props.accountId)}?tab=targeting`}>account targeting settings</Link>.</p>
        </div>
        */}

        <Confirm 
          title="Are you sure?"
          subtitle="This will remove the survey's custom targeting settings."
          show={this.state.showConfirm}
          onConfirm={() => {
            const poll = {};
            poll._id = this.props.poll._id;
            poll.targetingSettings = false;
            this.props.updatePoll(poll, 'Your changes have been saved and pushed live.');
            this.setState({ showTargetingSettings: false });
          }}
          onCancel={() => {
            this.setState({ showConfirm: false });
          }}
        />
      </div>
    );
  }
}

class GeneralTab extends Component {
  render() {
    return (
      <div>
        <PollEditForm
          key={this.props.poll._id}
          poll={this.props.poll}
          pollId={this.props.pollId}
          accountId={this.props.accountId}
          account={this.props.account}
          user={this.props.user}
        />

        {/*
        <PollToggles poll={this.props.poll} />
        */}
      </div>
    );
  }
}

class EmailTab extends Component {
  render() {
    return (<div>
      <PollEmailForm
        key={this.props.poll._id}
        poll={this.props.poll}
        pollId={this.props.pollId}
        accountId={this.props.accountId}
        account={this.props.account}
      />
    </div>)
  }
}

class Poll extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    if (!this.props.poll || !this.props.account) {
      this.props.fetchAccountAndPoll();
    }
  }

  updateButtons(buttons) {
    this.setState({ buttons: buttons });
  }

  componentDidMount() {
    if (!this.props.tab) {
      this.props.setParam('tab', 'general');
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.pollId !== prevProps.pollId) {
      this.props.fetchPoll();
    }
    if (!this.props.tab) {
      this.props.setParam('tab', 'general');
    }
  }

  render() {
    if (!this.props.poll || !this.props.account || !this.props.tab) {
      return (
        <ColumnLoading />
      );
    }

    let content;
    if (this.props.tab === 'general') {
      content = <GeneralTab {...this.props} />
    }
    if (this.props.tab === "design") {
      content = <DisplayTab {...this.props} />
    }
    if (this.props.tab === 'behavior') {
      content = <VisibilityTab {...this.props} />
    }
    if (this.props.tab === 'targeting') {
      content = <TargetingTab {...this.props} />
    }
    if (this.props.tab === 'language') {
      content = <LanguageTab {...this.props} />
    }
    if (this.props.tab === 'preview') {
      content = <PreviewTab {...this.props} />
    }
    if (this.props.tab === 'email') {
      content = <EmailTab {...this.props} />
    }    
    if (this.props.tab === 'manage') {
      content = <ManageTab {...this.props} />
    }
    if (this.props.tab === 'slides') {
      content = <SlidesTab {...this.props} />
    }

    return (
      <ColumnLayout
        title={`Survey → ${capitalize(this.props.tab)}`}
        className="poll"
        pageType="poll-edit"
      >

        { content }

        <LoadingIndicator loading={this.props.polls.loading || this.props.accounts.loading} />
      </ColumnLayout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const pollId = decode(ownProps.match.params.pollId);

  const accounts = state.accounts || [];
  const account = accounts[accountId];

  const polls = state.polls || [];
  const poll = polls[pollId];

  const user = state.user;

  return {
    accountId,
    accounts,
    account,
    pollId,
    polls,
    poll,
    user,
    tab: getTab(ownProps.location.search),
    params: getParams(ownProps.location.search),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PollActions, ...RouterActions, ...SlideActions, ...FlashNotificationActions }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Poll));
