import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import moment from 'moment';

import * as AccountActions from '../actions/Accounts';

import { decode, truncate } from '../utils';
import { Link, withRouter } from 'react-router-dom';

import Modal from './Modal';
import PaymentCaptureForm from './PaymentCaptureForm';

class PaywallModal extends Component {
  render() {
    if (!this.props.account) { return null; }
    if (!this.props.account.ccRequired) { return null; }

    const now = moment().unix();
    const expiration = this.props.account.trialExpiration;
    if (now - expiration < 0) { return null; }

    return (
      <Modal 
        isOpen={true}
        onRequestClose={() => {}}
      >
        <div className={`frame paywall ${this.props.accounts.loadingPayment ? 'loading' : ''}`}>
          <div className="title">Your free trial has ended</div>
          <div className="content">
            <div className="subtitle" style={{ marginBottom: 5 }}>Please enter your billing information below to continue using Zigpoll.</div>

            <div className="copy subtle">You will be charged $25 per month for the pro plan.</div>

            <PaymentCaptureForm
              show={true}
              label="Add Card"
              cancel={() => {}}
              contactUs={true}
              loading={this.props.accounts.loadingPayment}
              onSubmit={(token) => {
                this.props.convertTrial(token);
              }}
            />
          </div>
        </div>
      </Modal>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const accountId = decode(ownProps.match.params.accountId);
  const accounts = state.accounts || {};
  const account = accounts[accountId] || {};

  return {
    accounts,
    account,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AccountActions }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaywallModal));