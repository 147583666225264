import React, { Component } from 'react';
import Dropzone from 'react-dropzone'
import Modal from './Modal';

// Import the editor styles
import '@pqina/pintura/pintura.css';

// Import the editor default configuration
import { getEditorDefaults } from '@pqina/pintura';

// Import the editor component from `react-pintura`
import { PinturaEditor } from '@pqina/react-pintura';

// get default properties
const editorConfig = getEditorDefaults();

export default class SingleImageUploader extends Component {
  constructor(props) {
    super(props);
    const image = props.image ? { url: props.image } : null;
    this.state = { image: image };
  }

  clear() {
    this.setState({ image: null });
    this.props.onChange(null);
  }

  closeModal() {
    this.setState({ showImageEditorModal: false });
  }

  render() {
    const onDrop = (acceptedFiles) => {
      console.log('ondrop called');
      acceptedFiles.forEach((file) => {
        const reader = new FileReader()

        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
          const image = file;
          image.binary = reader.result;
          image.url = URL.createObjectURL(file)

          this.setState({ image: image }, () => {
            this.props.onChange(this.state.image);
          });
        }

        reader.readAsDataURL(file);
      }, [])
    };

    let content = (
      <Dropzone
        onDrop={onDrop}
        multiple={false}
        disabled={this.props.disabled}
        noDragEventsBubbling={true}
        accept="image/png, image/gif, image/jpeg, image/svg+xml"
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {
            isDragActive ?
              <p>Drop the Image</p> :
              <p>Drop the Image here, or click to upload</p>
          }
        </div>
        )}
      </Dropzone>
    );

    if (this.state.image) {
      content = (
        <div className="image-preview" style={{ maxWidth: this.props.maxWidth || null }}>
          <div className="remove" onClick={this.clear.bind(this)}/>
          <div className="edit" onClick={() => {
            this.setState({ showImageEditorModal: true });
          }} />
          <img src={this.state.image.url} />
        </div>
      );
    }

    let optional = null;
    if (this.props.optional) {
      optional = (<span>(Optional)</span>);
    }

    let label = this.props.label || (<label>Image{optional}</label>);
    if (this.props.hideLabel) {
      label = null;
    }

    let src = '';
    if (this.state.image) {
      src = this.state.image.url;
    }

    return (
      <div key={this.props.key} style={this.props.style} className={`single-image-uploader ${this.props.disabled ? 'disabled' : '' }`}>
        {label}

        { content }

        <Modal 
          isOpen={this.state.showImageEditorModal}
          onRequestClose={this.closeModal.bind(this)}
        >
          <div className="frame" style={{ width: 800, height: 800, maxWidth: 800, maxHeight: 800, overflow: 'hidden' }}>
            <div className="close" onClick={this.closeModal.bind(this)} />
            <div className="title">Edit Image</div>
            <div className="content" style={{ width: '100%', height: 'calc(100% - 60px)', padding: 0 }}>
              <PinturaEditor
                  {...editorConfig}
                  src={src}
                  imageCropAspectRatio={1}
                  stickers={['🎉','😍','😄','😐','😠','😡','👍','👎','⭐']}
                  cropSelectPresetFilter={'landscape'}
                  cropEnableButtonFlipVertical={true}
                  cropSelectPresetOptions={[
                      [undefined, 'Custom'],
                      [1, 'Square'],

                      // shown when cropSelectPresetFilter is set to 'landscape'
                      [2 / 1, '2:1'],
                      [3 / 2, '3:2'],
                      [4 / 3, '4:3'],
                      [16 / 10, '16:10'],
                      [16 / 9, '16:9'],

                      // shown when cropSelectPresetFilter is set to 'portrait'
                      [1 / 2, '1:2'],
                      [2 / 3, '2:3'],
                      [3 / 4, '3:4'],
                      [10 / 16, '10:16'],
                      [9 / 16, '9:16'],
                  ]}
                  onProcess={({ dest }) => {
                    const reader = new FileReader()

                    reader.onabort = () => console.log('file reading was aborted')
                    reader.onerror = () => console.log('file reading has failed')
                    reader.onload = () => {
                      const image = dest;
                      image.binary = reader.result;
                      image.url = URL.createObjectURL(dest)

                      this.setState({ image: image }, () => {
                        this.props.onChange(this.state.image);
                      });
                      this.closeModal();
                    }

                    reader.readAsDataURL(dest);
                  }}
              ></PinturaEditor>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}
